import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { HttpParserService } from 'src/app/services/http-parser/http-parser.service';
import { HttpClient } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { ChangeDetectorRef } from '@angular/core';
import { ChartInComponent } from '../chart-in/chart-in.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss'],
})
export class SalesComponent implements OnInit {
  @ViewChildren(ChartInComponent) chartComponents: QueryList<ChartInComponent>;

  sales: any = [];
  receiptDetail = false;
  receiptSrc = "";
  rcType = "Facturas"; rcTypet = "receipt";
  
  rcTypes = [
    {name: "Total Ventas", value: "all", icon: "storefront-outline", color: "#007be7", 
      data: [],
      values: 0, total: 0},
    {name: "Facturas", value: "receipt", icon: "receipt-outline", color: "#7a70ba", 
      data: [],
      values: 0, total: 0},
    {name: "Remisiones", value: "remission", icon: "reader-outline", color: "#47a2d6", 
      data: [],
      values: 0, total: 0}, 
    {name: "Cotizaciones", value: "price", icon: "clipboard-outline", color: "#d77748", 
      data: [],
      values: 0, total: 0},
    {name: "Anulaciones", value: "cancel", icon: "trash-bin-outline", color: "#c95e9e", 
      data: [],
      values: 0, total: 0}
  ];

  startDate = ""; 
  endDate = "";
  lastDate = "";
  todayDate = "";
  maxDate = "";

  store = "0";
  indexReceipt: any;
  constructor(
    private ch: ChangeDetectorRef,
    private toastController: ToastController,
    private httpParser: HttpParserService,
    private http: HttpClient,
    private storage: Storage,
    private router: Router
  ) { }

  ngOnInit() {
    this.calculateDates();
  }

  calculateDates() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const endDate = new Date(tomorrow);
    endDate.setDate(tomorrow.getDate() - 30);
    

    // Formatear las fechas a "YYYY-MM-DD"
    this.startDate = this.formatDate(endDate);
    this.endDate = this.formatDate(tomorrow);

    this.todayDate = this.formatDate(today);
    this.maxDate = this.formatDate(today);

    endDate.setDate(tomorrow.getDate());
    this.lastDate = this.formatDate(endDate);
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  selectStore(id) {
    this.store = (id -1).toString();
    console.log("Store??", id -1);

    for(let i = 0; i < 5; i++) {
      this.rcTypes[i].values = 0;
      this.rcTypes[i].total = 0;
      this.rcTypes[i].data = [];
    }

    for(let i of this.sales) {
      if (i.sb_store_id === this.store) {
        const start = new Date(i.creation_date);
        const end = new Date(this.endDate);
        const dayDifference = Math.ceil((end.getTime() - start.getTime()) / (1000 * 3600 * 24));

        const start_s = new Date(this.startDate);
        const end_s = new Date(i.creation_date);
        const dayDifference_s = Math.ceil((end_s.getTime() - start_s.getTime()) / (1000 * 3600 * 24));

        if (dayDifference > 0 && dayDifference_s >= -1) {
          i.show = true;
          if  (i.type === "receipt") {
            this.rcTypes[1].values += 1;
            this.rcTypes[1].total += Number(i.total);
            this.rcTypes[1].data.push({creation_date: i.creation_date});
  
            this.rcTypes[0].values += 1;
            this.rcTypes[0].total += Number(i.total);
            this.rcTypes[0].data.push({creation_date: i.creation_date});
          } else if(i.type === "remission") {
            this.rcTypes[2].values += 1;
            this.rcTypes[2].total += Number(i.total);
            this.rcTypes[2].data.push({creation_date: i.creation_date});
  
            this.rcTypes[0].values += 1;
            this.rcTypes[0].total += Number(i.total);
            this.rcTypes[0].data.push({creation_date: i.creation_date});
          } else if(i.type === "price") {
            this.rcTypes[3].values += 1;
            this.rcTypes[3].total += Number(i.total);
            this.rcTypes[3].data.push({creation_date: i.creation_date});
  
          } else if(i.type === "cancel") {
            this.rcTypes[4].values += 1;
            this.rcTypes[4].total += Number(i.total);
            this.rcTypes[4].data.push({creation_date: i.creation_date});
          }
        } else {
          i.show = false;
        }
      }
    }

    this.ch.detectChanges();
    setTimeout(() => {
      this.chartComponents.forEach((chartComponent) => {
        chartComponent.updateGraphics();
      });
    }, 200);
  }

  loadSales() {
    this.storage.get("token").then((tk) => {
      let postData: any = {
        token: tk,
      };

      const data = JSON.stringify(postData);
      
      this.httpParser.dcpayload(data, true)
        .then((postToSend: any) => {
          this.http.post(postToSend?.url + 'sales/get_sales.php',
          {
            data: postToSend?.value
          }, 
          {responseType: 'json'}
          ).pipe(finalize(() => {})).subscribe((data: any) => {
            if (data?.error) {
              if (data.error === '1-0') {
                this.presentToast('Error al guardar producto.');
              }
            } else if (data?.data) {
              this.httpParser.dcpayload(data.data, false).then((response: any) => {
                if(response.token) {
                  this.storage.set("token", response.token);

                  // if (this.sales.length !== JSON.parse(response.data).length) {
                  // }
                  this.sales = JSON.parse(response.data);
                  this.sales.reverse();

                  for(let i = 0; i < 5; i++) {
                    this.rcTypes[i].values = 0;
                    this.rcTypes[i].total = 0;
                    this.rcTypes[i].data = [];
                  }

                  for (let i of this.sales) {
                    i.client_detail = JSON.parse(i.client_detail); 
                    i.detail = JSON.parse(i.detail);
                    i.consecutive = this.numberToRef(Number(i.consecutive));

                    i.creation_date = i.creation_date.split(" ")[0];

                    if (i.sb_store_id === this.store) {
                      const start = new Date(i.creation_date);
                      const end = new Date(this.endDate);
                      const dayDifference = Math.ceil((end.getTime() - start.getTime()) / (1000 * 3600 * 24));
              
                      const start_s = new Date(this.startDate);
                      const end_s = new Date(i.creation_date);
                      const dayDifference_s = Math.ceil((end_s.getTime() - start_s.getTime()) / (1000 * 3600 * 24));
              
                      if (dayDifference > 0 && dayDifference_s >= -1) {
                        i.show = true;
                        if  (i.type === "receipt") {
                          this.rcTypes[1].values += 1;
                          this.rcTypes[1].total += Number(i.total);
                          this.rcTypes[1].data.push({creation_date: i.creation_date});
                
                          this.rcTypes[0].values += 1;
                          this.rcTypes[0].total += Number(i.total);
                          this.rcTypes[0].data.push({creation_date: i.creation_date});
                        } else if(i.type === "remission") {
                          this.rcTypes[2].values += 1;
                          this.rcTypes[2].total += Number(i.total);
                          this.rcTypes[2].data.push({creation_date: i.creation_date});
                
                          this.rcTypes[0].values += 1;
                          this.rcTypes[0].total += Number(i.total);
                          this.rcTypes[0].data.push({creation_date: i.creation_date});
                        } else if(i.type === "price") {
                          this.rcTypes[3].values += 1;
                          this.rcTypes[3].total += Number(i.total);
                          this.rcTypes[3].data.push({creation_date: i.creation_date});
                
                        } else if(i.type === "cancel") {
                          this.rcTypes[4].values += 1;
                          this.rcTypes[4].total += Number(i.total);
                          this.rcTypes[4].data.push({creation_date: i.creation_date});
                        }
                      } else {
                        i.show = false;
                      }
                    }
                  }
                  console.log(this.sales);
                  setTimeout(() => {
                    this.chartComponents.forEach((chartComponent) => {
                      chartComponent.updateGraphics();
                    });
                  }, 200);
                  this.ch.detectChanges();
                  this.setRcType(this.rcTypes[0].color);
                } else {
                  this.presentToast('Error: El producto no ha sido guardado')
                }
              }).catch(err => console.log(JSON.stringify(err)));
            }
          }, err => {
            if (err.error === '1-0') {
              this.presentToast('No existe ninguna cuenta con esta dirección de Email');
            }
            console.log(JSON.stringify(err));
          });
        });
    });
  }

  setRcType(id) {
    for(let i of this.rcTypes) {
      let title = document.getElementById(i.color + "txt");
      let card = document.getElementById(i.color);
      if(id === i.color) {
        title.style.color = `${i.color}`;
        card.style.border = `2px solid ${i.color}`;
        card.style.width =  `calc(20% - 28px)`;

        this.rcType = i.name;
        this.rcTypet = i.value;
      } else {
        title.style.color = `#a3a3a3`;
        card.style.border = `none`;
        card.style.width =  `calc(20% - 30px)`;
      }
    }
    console.log("Selected value");
  }

  openReceipt(url, index) {
    this.receiptSrc = url;
    this.receiptDetail = true;
    this.indexReceipt = index;
  }

  numberToRef(numero: number): string {
    let numeroTexto = numero.toString();
    if (numeroTexto.length < 5) {
      const cerosFaltantes = 5 - numeroTexto.length;
      numeroTexto = '0'.repeat(cerosFaltantes) + numeroTexto;
    }
    return numeroTexto;
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000
    });
    toast.present();
  }


  selectDate(event, start) {
    if(start) {
      this.todayDate = event.detail.value.split("T")[0];
      const ndDate = new Date(this.todayDate);
      const tomorrow = new Date(ndDate);
      tomorrow.setDate(ndDate.getDate() + 2);
      
      this.endDate = this.formatDate(tomorrow);
    } else {
      this.lastDate = event.detail.value.split("T")[0];
      const ndDate = new Date(this.lastDate);
      const tomorrow = new Date(ndDate);
      tomorrow.setDate(ndDate.getDate() + 2);
      
      this.startDate = this.formatDate(tomorrow);
    }

    this.store = (Number(this.store) + 1).toString();
    this.selectStore(this.store);
  }

  downloadImageWeb(url) {
    this.http.get(url, { responseType: 'blob' }).subscribe(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'downloaded-image.jpg';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }

  printImage(url) {
    this.storage.set("image-receipt", url).then(() => {
      this.router.navigate(['printreceipt']);
    });
  }

  cancelReceipt() {
    console.log(this.indexReceipt.id);

    this.storage.get("token").then((tk) => {
      let postData: any = {
        token: tk,
        identifier: this.indexReceipt.id,
        newType: "cancel",
        sb_store: (Number(this.store) + 1)
      };
      console.log(this.store);

      const data = JSON.stringify(postData);
      
      this.httpParser.dcpayload(data, true)
        .then((postToSend: any) => {
          this.http.post(postToSend?.url + 'receipts/cancel_receipt.php',
          {
            data: postToSend?.value
          }, 
          {responseType: 'json'}
          ).pipe(finalize(() => {})).subscribe((data: any) => {
            if (data?.error) {
              if (data.error === '1-0') {
                this.presentToast('Error al anular...');
              }
            } else if (data?.data) {
              this.httpParser.dcpayload(data.data, false).then((response: any) => {
                if(response.token) {
                  this.storage.set("token", response.token);
                  this.presentToast('Anulación completada');
                  this.receiptDetail = false;
                  setTimeout(() => {
                    this.loadSales();
                  }, 200);
                } else {
                  this.presentToast('Error al anular...');
                }
              }).catch(err => console.log(JSON.stringify(err)));
            }
          }, err => {
            if (err.error === '1-0') {
              this.presentToast('No existe ninguna cuenta con esta dirección de Email');
            }
            console.log(JSON.stringify(err));
          });
        });
    });
  }
}
