import { ChangeDetectorRef, Component, OnInit, ViewChildren, QueryList, ViewChild} from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpParserService } from 'src/app/services/http-parser/http-parser.service';
import { HttpClient } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { LoadingController, ToastController, IonDatetime} from '@ionic/angular';
import { ChartInComponent } from '../chart-in/chart-in.component';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';

registerLocaleData(localeEs);

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent implements OnInit {
  @ViewChildren(ChartInComponent) chartComponents: QueryList<ChartInComponent>;
  clients: any = [];
  selectedStore = 0;

  addingAccount = false;
  clTypes = [
    {
      name: "", value: "all", icon: "storefront-outline", color: "#007be7", 
      data: [], values: 0, total: 0
    }
  ]

  clDoughnut = [
    {name: "Total ventas", value: "all", icon: "storefront-outline", color: "#7a70ba", 
    data: [],
    values: 0, total: 0}
  ];

  clientPosLenght = 0;
  startDate = ""; 
  endDate = "";
  siginForm: any = [
    {
      id: 'name',
      name: 'Nombre*',
      type: 'text',
      value: '',
      require: true
    },
    {
      id: 'identify',
      name: 'Identificación*',
      type: 'text',
      value: '',
      require: true
    },
    {
      id: 'email',
      name: 'Email*',
      type: 'email',
      value: '',
      require: true
    },
    {
      id: 'tel',
      name: 'Número telefónico',
      type: 'number',
      value: '',
      require: false
    },
    {
      id: 'dir',
      name: 'Dirección*',
      type: 'text',
      value: '',
      require: true
    }
  ];

  birth_date = ""; b_d = "";
  birth_date_show = false;

  constructor(
    private toastController: ToastController,
    private ch: ChangeDetectorRef,
    private storage: Storage,
    private httpParser: HttpParserService,
    private http: HttpClient,
    private loadingController: LoadingController,
  ) { }

  ngOnInit() {
    this.calculateDates();
  }


  async loadClients() {
    this.storage.get("token").then(async (tk) => {
      let postData: any = {
        token: tk,
      };

      const data = JSON.stringify(postData);

      this.httpParser.dcpayload(data, true)
        .then((postToSend: any) => {
          this.http.post(postToSend?.url + 'accounts/get_clients.php',
          {
            data: postToSend?.value
          }, 
          {responseType: 'json'}
          ).pipe(finalize(() => {})).subscribe((data: any) => {
            if (data?.error) {
              if (data.error === '1-0') {
                this.presentToast('Error al guardar producto.');
              }
            } else if (data?.data) {
              this.httpParser.dcpayload(data.data, false).then((response: any) => {
                if(response.token) {
                  this.storage.set("token", response.token);
                  // susesfully
                  this.clients = JSON.parse(response.data);
                  this.clientPosLenght = 0;
                  this.clTypes[0].data = [];
                  for (let i of this.clients) {
                    i.show = false;
                    i.stores = JSON.parse(i.stores);
                    i.type = JSON.parse(i.type);
                    i.create_date = i.create_date.split(" ")[0];

                    if (i.type.tags === "client") {
                      i.type.tags = "Cliente";
                    }

                    for (let h of i.stores.auth) {
                      if (h === this.selectedStore) {
                        this.clientPosLenght += 1;
                        this.clTypes[0].data.push({creation_date: i.create_date});
                        i.show = true;
                        break;
                      }
                    }
                  }
                  this.clTypes[0].values = this.clientPosLenght;
                  
                  console.log(this.clients, "clnts");
                  this.loadActivity();
                  this.ch.detectChanges();
                } else if(response.error) {
                  this.presentToast('No tienes permisos para ver esta información');
                }
              }).catch(err => {
                this.presentToast('Error: No se ha podido cargar la información');
              });
            }
          }, err => {
            if (err.error === '1-0') {
              this.presentToast('No tienes permisos para ver esta información');
            }
            console.log(JSON.stringify(err));
          });
        });
    });
  }

  selectStore(id) {
    this.clientPosLenght = 0;
    this.clTypes[0].data = [];
    if (id != "all") {
      this.selectedStore =  id -1;
      console.log(id, "selected store");
      for (let i of this.clients) {
        i.show = false;
        if (i.type.tags === "client") {
          i.type.tags = "Cliente";
        }
  
        for (let h of i.stores.auth) {
          if (h === this.selectedStore) {
            this.clTypes[0].data.push({creation_date: i.create_date});
            this.clientPosLenght += 1;
            i.show = true;
            break;
          }
        }
      }
    } else {
      for (let i of this.clients) {
        i.show = true;
        if (i.type.tags === "client") {
          i.type.tags = "Cliente";
        }
      }
    }

    this.clTypes[0].values = this.clientPosLenght;
    this.loadActivity();
  }

  loadActivity() {
    this.clDoughnut[0].data = [this.clientPosLenght, 0, 0];
    setTimeout(() => {
      this.chartComponents.forEach((chartComponent) => {
        chartComponent.updateGraphics();
      });
    }, 200);
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000
    });
    toast.present();
  }

  calculateDates() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const endDate = new Date(tomorrow);
    endDate.setDate(tomorrow.getDate() - 30);
    

    // Formatear las fechas a "YYYY-MM-DD"
    this.startDate = this.formatDate(endDate);
    this.endDate = this.formatDate(tomorrow);
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  updateForm(index: any, event: any) {
    this.siginForm[index] = Object.assign(this.siginForm[index], {value: event.detail.value});
  }

  setDateTime(event) {
    console.log(event);
    this.b_d = event.detail.value;
    this.birth_date =  event.detail.value.split("T")[0];
  }

  async createClient() {
    if(this.birth_date === "") {
      this.presentToast("Seleccione una fecha de nacimiento");
    } else {
      let ct = true;
      for (let i of this.siginForm) {
        if (i.value === "") {
          ct = false;
        }
      }

      if (ct) {
        this.presentToast("Guardando...");
        this.storage.get("token").then( async (tk) => {
          let postData: any = {
            adding: true,
            name: this.siginForm[0].value,
            email: this.siginForm[2].value,
            identify: this.siginForm[1].value, //
            pass: this.siginForm[1].value,
            birthdate: this.b_d,
            phone_number: this.siginForm[3].value,
            dir: this.siginForm[4].value, //
            type: {tags: "client"},
            token: tk,
            avatar_url: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
            stores: {auth: [this.selectedStore]}
          };

          const data = JSON.stringify(postData);

          const loading = await this.loadingController.create({
            message: 'Creando cliente...',
            spinner: 'bubbles'
          });
          await loading.present();
          
          this.httpParser.dcpayload(data, true)
          .then((postToSend: any) => {
            this.http.post(postToSend?.url + 'accounts/add_client.php',
            {
              data: postToSend?.value
            }, 
            {responseType: 'json'}
            ).pipe(finalize(() => {loading.dismiss()})).subscribe((data: any) => {
              if (data?.error) {
                this.presentToast(data?.error);
              } else if (data?.data) {
                this.httpParser.dcpayload(data.data, false).then((response: any) => {
                  if (response.data) {
                    this.addingAccount = false;
                    this.presentToast('Cliente creado con éxito');
                    this.addingAccount = false;
                    this.b_d = ""; this.birth_date = "";
                    setTimeout(() => {
                      this.loadClients();
                    }, 200);
                  } else {
                    this.presentToast('Error: El Cliente no ha sido guardado');
                  }
                }).catch(err => this.presentToast('Error: No se ha podido registrar'));
              }
            }, err => {
              loading.dismiss();
              if (err.error === '1-0') {
                this.presentToast('Esta dirección de Email ya está en uso');
              }
            });
          });
        });
      } else {
        this.presentToast("Debe llenar todo el formulario antes de guardar");
      }
    }
  }
}
