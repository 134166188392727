import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { Chart, registerables } from 'chart.js';

@Component({
  selector: 'app-chart-in',
  templateUrl: './chart-in.component.html',
  styleUrls: ['./chart-in.component.scss'],
})
export class ChartInComponent implements OnInit {
  @ViewChild('staticsCanvas') private lineCanvas: ElementRef;
  @Input() color: string;
  @Input() dataValues: any;
  @Input() type: string;
  @Input() startDate: string;
  @Input() endDate: string;

  lineChart: any;
  doughnutChart: any;

  constructor() {
    Chart.register(...registerables);
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.updateGraphics();
  }

  updateGraphics() {
    if (this.type === "doughnut") {
      this.doughnutChartMethod("Actividad", this.dataValues);
    } else {
      const labels = this.getLast30Days(this.startDate, this.endDate);
      const data = this.generateDateCounts(this.dataValues, this.startDate, this.endDate);
      this.lineChartMethod("Registros", data, this.color, labels);
    }
  }

  lineChartMethod(label: string, data: number[], color: string, labels: string[]) {
    if (this.lineChart) {
      this.lineChart.destroy();
    }
    this.lineChart = new Chart(this.lineCanvas.nativeElement, {
      type: 'line',
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            display: false,
            grid: {
              drawOnChartArea: false
            }
          },
          y: {
            display: false,
            grid: {
              drawOnChartArea: false
            },
            beginAtZero: true,
            ticks: {
              padding: 10 // Ajusta el padding entre las etiquetas y el borde del canvas
            }
          }
        },
        layout: {
          padding: {
            top: 20
          }
        }
      },
      data: {
        labels: labels,
        datasets: [
          {
            label: label,
            fill: true,
            backgroundColor: (context) => {
              const chart = context.chart;
              const { ctx, chartArea } = chart;
    
              if (!chartArea) {
                // Este caso ocurre en el renderizado inicial del gráfico
                return null;
              }
    
              const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
              gradient.addColorStop(0, color); // El color principal
              gradient.addColorStop(1, 'rgba(75,192,192,0.0)'); // Transparente
    
              return gradient;
            },
            borderColor: color,
            borderWidth: 2,
            tension: 0.3,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: color,
            pointBackgroundColor: '#fff',
            pointBorderWidth: 4,
            pointHoverRadius: 6,
            pointHoverBackgroundColor: color,
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 10,
            data: data,
            spanGaps: false,
          }
        ]
      }
    });
    
  }

  doughnutChartMethod(label: string, data: number[]) {
    if (this.doughnutChart) {
      this.doughnutChart.destroy();
    }
    this.doughnutChart = new Chart(this.lineCanvas.nativeElement, {
      type: 'doughnut',
      data: {
        labels: ['POS', 'APP', 'WEB'],
        datasets: [{
          label: label,
          data: data,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)'
          ],
          borderWidth: 1
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              usePointStyle: true,
              pointStyle: 'circle'
            }
          },
          tooltip: {
            enabled: true,
          }
        }
      }
    });
  }

  getLast30Days(startDate: string, endDate: string): string[] {
    const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    const result: string[] = [];

    const start = new Date(startDate);
    const end = new Date(endDate);
    const dayDifference = Math.ceil((end.getTime() - start.getTime()) / (1000 * 3600 * 24));

    for (let i = 0; i < dayDifference + 1; i++) {
      const date = new Date(end);
      date.setDate(end.getDate() - i);

      const dayName = days[date.getDay()];
      const day = date.getDate();
      const month = date.getMonth() + 1; // Months are 0-based in JavaScript

      const formattedDate = `${dayName} ${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}`;
      result.push(formattedDate);
    }

    return result.reverse(); // To have the oldest date first
  }

  // Función para generar el array de conteo basado en un rango de fechas
  generateDateCounts(data: { creation_date: string }[], startDate: string, endDate: string): number[] {
    const start = new Date(startDate);
    start.setDate(start.getDate() - 1);
    const end = new Date(endDate);
    end.setDate(end.getDate() - 1);

    const length = this.dateDifferenceInDays(start, end) + 1;
    const counts = new Array(length).fill(0);
    const dateCounts: { [key: string]: number } = {};

    // Contar las fechas
    data.forEach(item => {
      const date = item.creation_date.split(' ')[0]; // Obtener solo la fecha, ignorar el tiempo
      if (this.isDateInRange(date, start, end)) {
        if (dateCounts[date]) {
          dateCounts[date]++;
        } else {
          dateCounts[date] = 1;
        }
      }
    });

    // Asignar los conteos al array
    Object.keys(dateCounts).forEach(date => {
      const index = this.getDateIndex(date, start);
      if (index < length) {
        counts[index] = dateCounts[date];
      }
    });

    return counts;
  }

  // Función para obtener el índice basado en la fecha y la fecha de inicio
  getDateIndex(date: string, startDate: Date): number {
    const currentDate = new Date(date);
    const diffTime = Math.abs(currentDate.getTime() - startDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  // Función para verificar si una fecha está dentro del rango especificado
  isDateInRange(date: string, startDate: Date, endDate: Date): boolean {
    const currentDate = new Date(date);
    return currentDate >= startDate && currentDate <= endDate;
  }

  // Función para calcular la diferencia en días entre dos fechas
  dateDifferenceInDays(startDate: Date, endDate: Date): number {
    const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }
}
