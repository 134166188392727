import { Component, OnInit, ElementRef, ViewChild, } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import SwiperCore, { Autoplay, Keyboard, Pagination, Scrollbar, Zoom, Navigation } from 'swiper';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { Chart, registerables } from 'chart.js';
import { ToastController } from '@ionic/angular';
import { HttpParserService } from 'src/app/services/http-parser/http-parser.service';
import { HttpClient } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { Storage } from '@ionic/storage';


SwiperCore.use([Autoplay, Keyboard, Pagination, Scrollbar, Zoom, Navigation]);

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss'],
})

export class InventoryComponent implements OnInit {
  @ViewChild('fileInput') fileInput: any;
  imagenSeleccionada: any;

  @ViewChild('staticsCanvas') private lineCanvas: ElementRef;
  lineChart: any;

  products: any = [];
  filter_by_store = "all";
  idStore = 0;
  store_items: any = [];

  toAddSection = 0;
  ptAdd = [
    {
      type: "",
      id: '', 
      name: 'Nombre del producto',
      decription: 'Descripción, ejemplo: Laptop gamer Asus TUF Gaming FX506LH negra 15.6", Intel Core i5 10300H 8GB de RAM 512GB SSD, NVIDIA GeForce GTX 1650 144 Hz 1920x1080px Windows 10', 
      decription_short: 'Descripción, ejemplo: Laptop gamer Asus TUF Gaming FX506LH negra 15.6", Intel Core i5 10300H 8GB de RAM 512GB SSD, NVIDIA GeForce GTX 1650 144 Hz 1920x1080px Windows 10', 
      item_code: "",
      bar_code: "",
      imgs: [
        'https://cdn.pixabay.com/photo/2013/04/01/21/30/photo-99135_1280.png',
      ],
      offer: 3691600,
      cost: 10000,
      sell_price: 10000,
      ppc: 100,
      prices: {
        contado: [],
        credito: [
          {name: "Precio de venta", int: true, value: 18000, enable: true, disabled: false, edit: false},
          {name: "Descuento", int: false, value: 5, enable: false, disabled: true, edit: true},
          {name: "Mínimo de ventas para aplicar descuento", int: false, value: 5, enable: false, disabled: true, edit: true}
        ],
        c_credito: []
      },
      taxes: [
        {name: "IVA (Aplica sobre el valor total)", int: false, value: 19, enable: true, disabled: false, edit: false, type: "all"},
        {name: "Pasarela de pagos (Aplica sólo a compras online)", int: false, value: 3.5,  enable: true, disabled: false, edit: false, type: "online"},
        {name: "Impuesto sobre la renta (Aplica sobre utilidades)", int: false, value: 35,  enable: false, disabled: true, edit: false,  type: "profit"},
        {name: "Entrega o envío (Aplica sólo a compras online)", int: true, value: 15000, enable: false, disabled: true, edit: false, type: "online"},
        {name: "Costos de importación", int: true, value: 100, enable: true, disabled: false, edit: true, type: "all"},
        {name: "Costos de distribución", int: true, value: 100, enable: true, disabled: false, edit: true, type: "all"}
      ],
      images: [],
      image_front: '',
      profit: 0,
      profit_cr: 0,
      stores: [],
      alert_stock: 5,
      available: true,
      visible: 'eye-off-outline',
      expo: 1
    }
  ]

  itemsPopCat = [
    {name: "Mostrador", icon: "storefront-outline", profit: 0, value: 0, type: "all"}, 
    {name: "Tienda web", icon: "desktop-outline", profit: 0, value: 0, type: "online"}, 
    {name: "App móvil", icon: "phone-portrait-outline", profit: 0, value: 0, type: "online"}, 
    {name: "Distribuidores", icon: "people-outline", profit: 0, value: 0, type: "all"}
  ]

  buttonsMenu = [
    {id: 'products', text: 'General', class: 'border-row-c'},
    {id: 'sales', text: 'Agregar', class: 'border-row-off-c'},
    {id: 'users', text: 'Eliminar', class: 'border-row-off-c'},
    {id: 'historial', text: 'Historial', class: 'border-row-off-c'},
    {id: 'balance', text: 'Balance', class: 'border-row-off-c'},
  ];

  selectionImage: any = []; isZoomImage = true; indexSwipImage = 0;
  buttonsMenuSelect = 0;
  isSbmEnabled = true;
  tagInfo = 'btn-storefront';
  cardContentType = 'images';
  imagePosSelected: number;

  rstInv = false;

  globalStatics = [
    {label: "Productos", data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]},
    {label: "Stock", data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]},
    {label: "Capital", data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]},
    {label: "Ventas", data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]},
    {label: "Utilidad", data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]},
  ]
  globalItems = 0; 
  globalStock = 0; 
  globalCapital = 0;

  constructor(
    private ch: ChangeDetectorRef,
    private toastController: ToastController,
    private httpParser: HttpParserService,
    private http: HttpClient,
    private storage: Storage
    ) {
      Chart.register(...registerables);
    }

  ngOnInit() {
    this.storage.create();
    registerLocaleData(es);
    this.storage.get("stores").then((result) => {
      this.store_items = JSON.parse(result);
      for (let i of this.store_items) {
        i.add = false;
        i.stock = 0;
      }
    });
  }

  // ngAfterViewInit() {
  //   this.lineChartMethod();
  // }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000
    });
    toast.present();
  }

  selectMenu(index) {
    this.buttonsMenu[this.buttonsMenuSelect].class = 'border-row-off-c';
    this.buttonsMenu[index].class = 'border-row-c';
    this.buttonsMenuSelect = index;
  }

  sbmController() {
    if(this.isSbmEnabled) {
      document.getElementById('butt-sbm').className = 'close-anim-menu-button-sbm md ion-color ion-color-primary hydrated';
      document.getElementById('menu-inventory').className = 'menu-inv menu-inv-close';
      document.getElementById('shadow-back-it').className = 'background-shadow background-shadow-close';
      this.isSbmEnabled = false;
    } else {
      document.getElementById('butt-sbm').className = 'open-anim-menu-button-sbm md ion-color ion-color-primary hydrated';
      document.getElementById('menu-inventory').className = 'menu-inv menu-inv-open'; 
      document.getElementById('shadow-back-it').className = 'background-shadow background-shadow-open';
      this.isSbmEnabled = true;
    }
  }

  useSwip(index, to) {
    let swiper: any = document.getElementById('swipIdent' + index + 'inv').getElementsByTagName('swiper')[0];
    if(to === 'right') {
      swiper.swiper.slideNext(250);
    } else {
      swiper.swiper.slidePrev(250);
    }
  }

  showProductImages(data) {
    this.cardContentType = 'images';
    if(this.isZoomImage) {
      this.selectionImage = data;
      document.getElementById('image-visualizer-center').className = 'image-visualizer show-elements';
    }

    let swp: any = document.getElementById('sw-im-vi');
    swp.swiper.slideTo(0);
  }

  closeProductImages() {
    this.selectionImage = [];
    document.getElementById('image-visualizer-center').className = 'image-visualizer hide-elements';
  }


  selectButtonItem(pressed) {
    let base = ' md button button-clear button-has-icon-only ion-activatable ion-focusable hydrated ion-activated';
    const buttons = [
      {
        id:  'btn-reader',
      },
      {
        id:  'btn-storefront'
      },
      {
        id:  'btn-add-item'
      },
      {
        id:  'btn-stats'
      }
    ];

    for(let i of buttons) {
      if(pressed === i.id) {
        document.getElementById(i.id).className = 'btn-green' + base;

        if(this.tagInfo !== i.id) {
          if(i.id === 'btn-add-item' && this.tagInfo !== 'btn-stats') {
            document.getElementById('inventory-caps').className = 'full slide-caps-right';
            document.getElementById('inventory-add-item').className = 'full slide-caps-left-return'; 
          } else if(i.id === 'btn-stats' && this.tagInfo !== 'btn-add-item'){
            document.getElementById('inventory-caps').className = 'full slide-caps-left';
            document.getElementById('inventory-stats').className = 'full slide-caps-right-return'; 
          } else {
            
            // document.getElementById('inventory-add-item').className = 'full slide-caps-left';
            // document.getElementById('inventory-stats').className = 'full slide-caps-right';  
          }

          if(this.tagInfo === 'btn-add-item' && i.id === 'btn-stats') {
            document.getElementById('inventory-add-item').className = 'full slide-caps-left'; 
            document.getElementById('inventory-stats').className = 'full slide-caps-right-return';
            // document.getElementById('inventory-caps').className = 'full slide-caps-right-return';
          } else if(this.tagInfo === 'btn-stats' && i.id === 'btn-add-item') {
            document.getElementById('inventory-add-item').className = 'full slide-caps-left-return'; 
            document.getElementById('inventory-stats').className = 'full slide-caps-right';
            // document.getElementById('inventory-caps').className = 'full slide-caps-right-return';
          } else if(this.tagInfo === 'btn-add-item') {
            document.getElementById('inventory-add-item').className = 'full slide-caps-left'; 
            document.getElementById('inventory-caps').className = 'full slide-caps-right-return';
          } else if (this.tagInfo === 'btn-stats') {
            document.getElementById('inventory-stats').className = 'full slide-caps-right';
            document.getElementById('inventory-caps').className = 'full slide-caps-left-return';
          }

          this.tagInfo = i.id;
        }

      } else {
        document.getElementById(i.id).className = 'btn-gray' + base;
      }
    }
  }

  hideStoreItem(index) {
    if (index.prd_estado === "active") {
      index.prd_estado = "inactive";
      this.presentToast("Producto oculto a todo público");
    } else {
      index.prd_estado = "active";
      this.presentToast("Producto visible a todo público");
    }
  }

  showGrapics() {
    this.cardContentType = 'graphycs';
    document.getElementById('image-visualizer-center').className = 'image-visualizer show-elements';
  }

  selectStore(id) {
    this.filter_by_store = id;
    this.globalItems = 0; this.globalStock = 0; this.globalCapital = 0;

    if (this.filter_by_store !== "all") {
      for (let i of this.products) {
        for (let y of i.stores) {
          if (y.store_id === this.filter_by_store) {
            this.globalItems += 1;
            this.globalStock += y.stock;
            this.globalCapital += Number(i.prd_cost) * y.stock;

            i.visible = true;
            break;
          } else {
            i.visible = false;
          }
        }
      }
    } else {
      this.globalItems = this.products.length;
      for (let i of this.products) {
        for (let y of i.stores) {
          this.globalStock += y.stock;
          this.globalCapital += Number(i.prd_cost) * y.stock;
        }
      }
    }

    this.globalStatics[0].data[2] = this.globalItems;
    this.globalStatics[1].data[2] = this.globalStock;
    this.globalStatics[2].data[2] = this.globalCapital;
    this.ch.detectChanges();
  }

  generateCharts(chart) {
    this.lineChartMethod(this.globalStatics[chart].label, this.globalStatics[chart].data);
  }

  lineChartMethod(label, data) {
    if (this.lineChart) {
      this.lineChart.data.datasets[0].label = label;
      this.lineChart.data.datasets[0].data = data;
      this.lineChart.update();
    } else {
      this.lineChart = new Chart(this.lineCanvas.nativeElement, {
        type: 'line',
        options : {
          plugins: {
            legend: {
              display: false
            }
          }
        },
        data: {
          labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          datasets: [
            {
              label: label,
              fill: true,
              // lineTension: 0.1,
              backgroundColor: 'rgba(75,192,192,0.4)',
              borderColor: 'rgba(75,192,192,1)',
              borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: 'rgba(75,192,192,1)',
              pointBackgroundColor: '#fff',
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: 'rgba(75,192,192,1)',
              pointHoverBorderColor: 'rgba(220,220,220,1)',
              pointHoverBorderWidth: 2,
              pointRadius: 4,
              pointHitRadius: 10,
              // data: [65, 59, 80, 81, 56, 55, 40, 10, 5, 50, 10, 15, 0],
              data: data,
              spanGaps: false,
            }
          ]
        }
      });
    }
  }

  async typeToAdd(type) {
    this.ptAdd[0].type = type;
    this.toAddSection += 1;

    let swip: any = document.getElementById('selectionSwiper');
    await swip.swiper.enable();
    await swip.swiper.slideNext();
    // swip.swiper.disable();
  }

  async navigateSectionAdd(direction) {
    if(direction) {
      this.toAddSection = this.toAddSection + 1;
    } else {
      this.toAddSection = this.toAddSection - 1;
    }

    if (this.toAddSection >= 3) {
      this.toAddSection = 3;
    }


    let swip: any = document.getElementById('selectionSwiper');
    await swip.swiper.enable();
    if(direction) {
      await swip.swiper.slideNext();
    } else {
      await swip.swiper.slidePrev();
    }
    // swip.swiper.disable();
  }

  setValue(ev) {
    this.ptAdd[0].cost = Number(ev.detail.value);
  }

  addAditional(selection) {
    if (selection == 1) {
      this.ptAdd[0].taxes.push({
        name: "Costo o impuesto adicional", int: true, value: 100, enable: true, disabled: false, edit: true, type: "all"
      });
    } else if(selection == 2) {
      this.ptAdd[0].prices.contado.push({
        name: "Seleccionar categoría", 
        int: false, 
        value: 5, 
        enable: true, 
        disabled: false, 
        edit: true, 
        total: true,
        icon: "help-circle-outline"
      });
    } else if(selection == 3) {
      this.ptAdd[0].prices.c_credito.push(
        {
          name: "Distribuidores", int: false, value: 5, enable: true, edit: true
        });
    }
  }

  removeAditional(selection, index) {
    if(selection == 1) {
      let container:any = [];
      for(let i in this.ptAdd[0].taxes) {
        if(index !== Number(i)) {
          container.push(this.ptAdd[0].taxes[i]);
        }
      }
      this.ptAdd[0].taxes = container;
    } else if(selection == 2) {
      let container:any = [];
      for(let i in this.ptAdd[0].prices.contado) {
        if(index !== Number(i)) {
          container.push(this.ptAdd[0].prices.contado[i]);
        }
      }
      this.ptAdd[0].prices.contado = container;
    } else if(selection == 3) {
      let container:any = [];
      for(let i in this.ptAdd[0].prices.c_credito) {
        if(index !== Number(i)) {
          container.push(this.ptAdd[0].prices.c_credito[i]);
        }
      }
      this.ptAdd[0].prices.c_credito = container;
    }

    this.setSellPrice()
  }

  chageType(selection, index) {
    if(selection == 0) {
      if (this.ptAdd[0].taxes[index].edit) {
        if (!this.ptAdd[0].taxes[index].int) {
          this.ptAdd[0].taxes[index].int = true;
        } else {
          this.ptAdd[0].taxes[index].int = false;
        }
      } else {
        this.presentToast("Esta opción no es editable");
      }
    } else if (selection == 1) {
      if (this.ptAdd[0].taxes[index].edit) {
        if (!this.ptAdd[0].taxes[index].int) {
          this.ptAdd[0].taxes[index].int = true;
        } else {
          this.ptAdd[0].taxes[index].int = false;
        }
      } else {
        this.presentToast("Esta opción no es editable");
      }
    } else if (selection == 2) {
      if (this.ptAdd[0].prices.contado[index].edit) {
        if (!this.ptAdd[0].prices.contado[index].int) {
          this.ptAdd[0].prices.contado[index].int = true;
        } else {
          this.ptAdd[0].prices.contado[index].int = false;
        }
      } else {
        this.presentToast("Esta opción no es editable");
      }
    } else if (selection == 3) {
      if (this.ptAdd[0].prices.credito[index].edit) {
        if (!this.ptAdd[0].prices.credito[index].int) {
          this.ptAdd[0].prices.credito[index].int = true;
        } else {
          this.ptAdd[0].prices.credito[index].int = false;
        }
      } else {
        this.presentToast("Esta opción no es editable");
      }
    } else if (selection == 4) {
      // if (this.ptAdd[0].prices.c_contado[index].edit) {
      //   if (!this.ptAdd[0].prices.c_contado[index].int) {
      //     this.ptAdd[0].prices.c_contado[index].int = true;
      //   } else {
      //     this.ptAdd[0].prices.c_contado[index].int = false;
      //   }
      // } else {
      //   this.presentToast("Esta opción no es editable");
      // }
    } else if (selection == 5) {
      if (this.ptAdd[0].prices.c_credito[index].edit) {
        if (!this.ptAdd[0].prices.c_credito[index].int) {
          this.ptAdd[0].prices.c_credito[index].int = true;
        } else {
          this.ptAdd[0].prices.c_credito[index].int = false;
        }
      } else {
        this.presentToast("Esta opción no es editable");
      }
    }
    this.setSellPrice();
  }

  changeEnabled(selection, index) {
    if(selection == 0) {
      if (this.ptAdd[0].taxes[index].disabled) {
        this.ptAdd[0].taxes[index].disabled = false;
        this.ptAdd[0].taxes[index].enable = true;
      } else {
        this.ptAdd[0].taxes[index].disabled = true;
        this.ptAdd[0].taxes[index].enable = false;
      }
    } else if(selection == 2) {
      if (this.ptAdd[0].prices.contado[index].disabled) {
        this.ptAdd[0].prices.contado[index].disabled = false;
        this.ptAdd[0].prices.contado[index].enable = true;
      } else {
        this.ptAdd[0].prices.contado[index].disabled = true;
        this.ptAdd[0].prices.contado[index].enable = false;
      }
    } else if(selection == 3) {
      if (this.ptAdd[0].prices.credito[index].disabled) {
        this.ptAdd[0].prices.credito[index].disabled = false;
        this.ptAdd[0].prices.credito[index].enable = true;
      } else {
        this.ptAdd[0].prices.credito[index].disabled = true;
        this.ptAdd[0].prices.credito[index].enable = false;
      }
    }
  }

  setSellPrice() {
    let input_cost: any = document.getElementById("cost-pc");
    let input_sellprice: any = document.getElementById("sell-price");

    this.ptAdd[0].cost = Number(input_cost.value);
    this.ptAdd[0].sell_price = Number(input_sellprice.value);
    //Get ContPrices
    for (let u of this.itemsPopCat) {
      u.profit = this.ptAdd[0].sell_price - this.ptAdd[0].cost;
      u.value = this.ptAdd[0].sell_price;
    }

    for(let i in this.ptAdd[0].prices.contado) {
      let prices_c: any = document.getElementById("pvc-" + i.toString());
      this.ptAdd[0].prices.contado[i].value = Number(prices_c.value);

      // set prices first
      if (this.ptAdd[0].prices.contado[i].enable) {
        if (this.ptAdd[0].prices.contado[i].int) {
          for (let u of this.itemsPopCat) {
            if (u.name === this.ptAdd[0].prices.contado[i].name) {
              u.profit = this.ptAdd[0].prices.contado[i].value - this.ptAdd[0].cost;
              u.value = this.ptAdd[0].prices.contado[i].value;
            }
          }
        }
      }
    }

    for(let i in this.ptAdd[0].prices.contado) {
      let prices_c: any = document.getElementById("pvc-" + i.toString());
      this.ptAdd[0].prices.contado[i].value = Number(prices_c.value);

      // set discounts
      if (this.ptAdd[0].prices.contado[i].enable) {
        if (!this.ptAdd[0].prices.contado[i].int) {
          for (let u of this.itemsPopCat) {
            if (u.name === this.ptAdd[0].prices.contado[i].name) {
              if (this.ptAdd[0].prices.contado[i].total) {
                u.profit = (u.profit -(u.value / 100) * this.ptAdd[0].prices.contado[i].value);
              } else if (this.ptAdd[0].prices.contado[i].total) {
                u.profit = (u.profit -(u.profit / 100) * this.ptAdd[0].prices.contado[i].value);
              }
            }
          }
        }
      }
    }

    // set taxes
    for (let u of this.itemsPopCat) {
      for(let j in this.ptAdd[0].taxes) {
        let input_taxes: any = document.getElementById("taxes-" + j.toString());
        this.ptAdd[0].taxes[j].value = input_taxes.value;

        if (this.ptAdd[0].taxes[j].enable) {          
          if (!this.ptAdd[0].taxes[j].int) {
            if(this.ptAdd[0].taxes[j].type === "all" || this.ptAdd[0].taxes[j].type === u.type) {
              u.profit = (u.profit -(u.value / 100) * this.ptAdd[0].taxes[j].value);
            }
          } else if(this.ptAdd[0].taxes[j].type === "all" || this.ptAdd[0].taxes[j].type === u.type) {
            u.profit = u.profit - this.ptAdd[0].taxes[j].value;
          }
        }
      }
    }

    for (let u of this.itemsPopCat) {
      for(let j in this.ptAdd[0].taxes) {
        let input_taxes: any = document.getElementById("taxes-" + j.toString());
        this.ptAdd[0].taxes[j].value = input_taxes.value;

        if (this.ptAdd[0].taxes[j].enable) {       
          if (!this.ptAdd[0].taxes[j].int) {
            if (this.ptAdd[0].taxes[j].type === "profit") {
              u.profit = u.profit - ((u.profit / 100) * this.ptAdd[0].taxes[j].value);
            }
          }
        }
      }
    }
  }

  steCategorySellName(storeValue, icon, index, type) {
    if (type == 0) {
      this.ptAdd[0].prices.contado[index].name = storeValue;
      this.ptAdd[0].prices.contado[index].icon = icon;
    }
    document.getElementById('btn-select-store-invs').click();
  }


  seleccionarImagen(pos) {
    this.fileInput.nativeElement.click();
    this.imagePosSelected = pos;
  }

  onFileSelected(event) {
    // Cuando el usuario selecciona una imagen, se maneja aquí
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imagenSeleccionada = e.target.result;
        if (this.imagePosSelected === 1) {
          this.ptAdd[0].image_front = this.imagenSeleccionada;

          this.ptAdd[0].images.push({value: this.imagenSeleccionada});
        } else {
          this.ptAdd[0].images.push({value: this.imagenSeleccionada});
        }

        this.imagenSeleccionada = "";
      };
      reader.readAsDataURL(file);
    }
  }


  saveProductData() {
    this.presentToast("Guardando...");
    this.storage.get("token").then((tk) => {

      let postData: any = {
        name: this.ptAdd[0].name,
        item_code: this.ptAdd[0].item_code,
        bar_code: this.ptAdd[0].bar_code,
        state: "active",
        decription_short: this.ptAdd[0].decription_short,
        decription: this.ptAdd[0].decription,
        purchase_price: this.ptAdd[0].cost,
        sale_price: this.itemsPopCat,
        credit_sale_price: this.itemsPopCat,
        // stock: this.ptAdd[0].stock,
        alert_stock: this.ptAdd[0].alert_stock,
        taxes: this.ptAdd[0].taxes,
        images: this.ptAdd[0].images,
        image_front: this.ptAdd[0].image_front,
        stores: this.ptAdd[0].stores,
        variants: [],
        type:  this.ptAdd[0].type,
        token: tk,
      };

      const data = JSON.stringify(postData);
      console.log(postData, "values to send");

      this.httpParser.dcpayload(data, true)
        .then((postToSend: any) => {
          this.http.post(postToSend?.url + 'products/add_item.php',
          {
            data: postToSend?.value
          }, 
          {responseType: 'json'}
          ).pipe(finalize(() => {})).subscribe((data: any) => {
            if (data?.error) {
              if (data.error === '1-0') {
                this.presentToast('Error al guardar producto.');
              }
            } else if (data?.data) {
              this.httpParser.dcpayload(data.data, false).then((response: any) => {
                if(response.token) {

                  this.storage.set("token", response.token);
                  document.getElementById("btn-storefront-invs").click();
                  this.toAddSection = 0;
                  this.ptAdd[0].image_front = "";
                  this.ptAdd[0].images = [];
                  
                  this.rstInv = true;
                  setTimeout(() => {
                    this.rstInv = false;  
                  }, 500);
                } else {
                  this.presentToast('Error: El producto no ha sido guardado')
                }
              }).catch(err => this.presentToast('Error: No se ha podido registrar'));
            }
          }, err => {
            if (err.error === '1-0') {
              this.presentToast('No existe ninguna cuenta con esta dirección de Email');
            }
          });
        });
    });
  }

  loadProducts(idload) {
    this.globalItems = 0; 
    this.globalStock = 0; 
    this.globalCapital = 0;
    this.idStore = idload - 1;
    this.storage.get("token").then((tk) => {
      let postData: any = {
        token: tk,
      };

      const data = JSON.stringify(postData);
      
      this.httpParser.dcpayload(data, true)
        .then((postToSend: any) => {
          this.http.post(postToSend?.url + 'products/get_products.php',
          {
            data: postToSend?.value
          }, 
          {responseType: 'json'}
          ).pipe(finalize(() => {})).subscribe((data: any) => {
            if (data?.error) {
              if (data.error === '1-0') {
                this.presentToast('Error al guardar producto.');
              }
            } else if (data?.data) {
              this.httpParser.dcpayload(data.data, false).then((response: any) => {
                if(response.token) {
                  this.storage.set("token", response.token);

                  if (this.products.length !== JSON.parse(response.data).length) {
                    this.products = JSON.parse(response.data);
                    this.globalItems = this.products.length;
                    for(let i of this.products) {
                      i.prd_sale_prices = JSON.parse(i.prd_sale_prices);
                      i.ssp = 0; i.txp = 0;
                      i.prd_taxes = JSON.parse(i.prd_taxes);
                      i.prd_variants = JSON.parse(i.prd_variants);
                      i.prd_images = JSON.parse(i.prd_images);
                      i.stores = JSON.parse(i.stores);
                      i.visible = true;
                      i.prd_stock = Number(i.prd_stock);
                      i.prd_alert_stock = Number(i.prd_alert_stock);
                
                      let cont_taxes = [];
                      for(let h of i.prd_taxes) {
                        let ind = "$";
                        if (!h.int) {
                          ind = "%";
                        }
  
                        let name = h.name.split("(")[0];
                        if(h.enable) {
                          cont_taxes.push({name: name, id: ind, value: h.value, enable: h.enable});
                        }
                      }
                      i.prd_taxes = cont_taxes;
  
                      for(let h of i.stores) {
                        this.globalStock += h.stock;
                        this.globalCapital += Number(i.prd_cost) * h.stock;
                      }
                    }
                    this.globalStatics[0].data[2] = this.globalItems;
                    this.globalStatics[1].data[2] = this.globalStock;
                    this.globalStatics[2].data[2] = this.globalCapital;
  
                    this.lineChartMethod(this.globalStatics[0].label, this.globalStatics[0].data);
                    this.ch.detectChanges();
  
                    this.selectStore(idload);
                  }
                } else {
                  this.presentToast('Error: El producto no ha sido guardado')
                }
              }).catch(err => console.log(JSON.stringify(err)));
            }
          }, err => {
            if (err.error === '1-0') {
              this.presentToast('No existe ninguna cuenta con esta dirección de Email');
            }
            console.log(JSON.stringify(err));
          });
        });
    });
  }

  setStockStore(index, disable, ev) {
    if (disable) {
      if (this.store_items[index].add) {
        this.store_items[index].add = false;
      } else {
        this.store_items[index].add = true;
      }
    } else {
      this.store_items[index].stock = Number(ev.detail.value);
    }

    let container: any = [];
    for (let i of this.store_items) {
      if (i.add && i.stock >= 1) {
        container.push({store_id: i.store_id, store_name: i.store_name, visible: true, stock: i.stock});
      }
    }
    this.ptAdd[0].stores = container;
  }
}
