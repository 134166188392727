import { ChangeDetectorRef, Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import { ToastController, LoadingController, IonDatetime, Platform} from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { HttpParserService } from 'src/app/services/http-parser/http-parser.service';
import { HttpClient } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import html2canvas from 'html2canvas';
import { PDFDocument, rgb } from 'pdf-lib';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss'],
})
export class StoreComponent implements OnInit {
  @ViewChild('receiptSC') receiptSC: ElementRef;
  @ViewChild('myDatetime') myDatetime: IonDatetime;

  birth_date = ""; today = ""; expire = ""
  b_d = "";

  elementType: 'url' | 'canvas' | 'img' = 'canvas';
  qrcodeData = 'Que está mirando?';

  products: any = [];
  filter_by_store = "all";
  id_store = 0;
  storesAvailables: any = [{business_name: "Todo"}];

  //bags
  selectedBag = 0;
  bags: any = [{client: {name: "", id_c: "", tel: "", dir: ""}, items: []}];

  //receipt
  totalPriceBag = 0; subPriceBag = 0; taxesBag = 0;
  confAlt = false; refReceipt = "Pendiente";

  clients: any = [];
  sugestClients: any = [];
  payList: any = []; valuePayList = 0;
  
  //receipt options
  saveImage = false; printImage = false; sendWhatsapp = false;

  //selectors
  typeReceipt = 0; receipts: any = ["receipt", "remission", "price"]; 
  pendingReceipt = false; 
  showDataReceipt = false; 
  waterMark = false;
  payMethod = false;
  dataReceipt = "https://globalapps.com.co/receipts.php?id=";
  trace = ""; receiptTrace = "";

  //adding client
  birth_date_show = false;
  addingAccount = false;
  siginForm: any = [
    {
      id: 'name',
      name: 'Nombre*',
      type: 'text',
      value: '',
      require: true
    },
    {
      id: 'identify',
      name: 'Identificación*',
      type: 'text',
      value: '',
      require: true
    },
    {
      id: 'email',
      name: 'Email*',
      type: 'email',
      value: '',
      require: true
    },
    {
      id: 'tel',
      name: 'Número telefónico',
      type: 'number',
      value: '',
      require: false
    },
    {
      id: 'dir',
      name: 'Dirección*',
      type: 'text',
      value: '',
      require: true
    }
  ];


  constructor(
    private toastController: ToastController,
    private ch: ChangeDetectorRef,
    private storage: Storage,
    private httpParser: HttpParserService,
    private http: HttpClient,
    private loadingController: LoadingController,
    private platform: Platform,
    private router: Router
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.loadProducts();
    let td = new Date();
    this.today = (td.getMonth() + 1).toString() + "/" + (td.getDate()).toString() + "/" + (td.getFullYear()).toString();
    td.setDate(td.getDate() + 2);
    this.expire = (td.getMonth() + 1).toString() + "/" + (td.getDate()).toString() + "/" + (td.getFullYear()).toString();
    
    this.storage.get("stores").then((result) => {
      this.storesAvailables = JSON.parse(result);
      this.id_store = 0;
      this.filter_by_store = this.storesAvailables[0].store_id;
      console.log(this.storesAvailables);
    });

    this.getReceiptState();
  }

  loadProducts() {
    this.storage.get("token").then((tk) => {
      let postData: any = {
        token: tk,
      };
      const data = JSON.stringify(postData);
      this.httpParser.dcpayload(data, true)
        .then((postToSend: any) => {
          this.http.post(postToSend?.url + 'products/get_products.php',
          {
            data: postToSend?.value
          }, 
          {responseType: 'json'}
          ).pipe(finalize(() => {})).subscribe((data: any) => {
            if (data?.error) {
              if (data.error === '1-0') {
                this.presentToast('Error al guardar producto.');
              }
            } else if (data?.data) {
              this.httpParser.dcpayload(data.data, false).then((response: any) => {
                if(response.token) {
                  this.storage.set("token", response.token);

                  // if(this.products.length !== JSON.parse(response.data).length) {
                  // }
                  
                  this.products = JSON.parse(response.data);
                  for(let i of this.products) {
                    i.prd_sale_prices = JSON.parse(i.prd_sale_prices);
                    i.ssp = 0; i.txp = 0;
                    i.prd_taxes = JSON.parse(i.prd_taxes);
                    i.prd_variants = JSON.parse(i.prd_variants);
                    i.prd_images = JSON.parse(i.prd_images);
                    i.stores = JSON.parse(i.stores);
                    i.visible = true;
                    i.prd_stock = Number(i.prd_stock);
                    i.prd_alert_stock = Number(i.prd_alert_stock);
              
                    let cont_taxes = [];
                    for(let h of i.prd_taxes) {
                      let ind = "$";
                      if (!h.int) {
                        ind = "%";
                      }

                      let name = h.name.split("(")[0];
                      if(h.enable) {
                        cont_taxes.push({name: name, id: ind, value: h.value, enable: h.enable});
                      }
                    }
                    i.prd_taxes = cont_taxes;
                  }
                  this.selectStore(this.filter_by_store);
                  this.ch.detectChanges();
                } else {
                  this.presentToast('Error: El producto no ha sido guardado')
                }
              }).catch(err => console.log(JSON.stringify(err)));
            }
          }, err => {
            if (err.error === '1-0') {
              this.presentToast('No existe ninguna cuenta con esta dirección de Email');
            }
            console.log(JSON.stringify(err));
          });
        });
    });
  }

  selectStore(id) {
    this.bags  = [{client: {name: "", cc: "", tel: "", dir: ""}, items: []}];
    this.totalPriceBag = 0;

    this.filter_by_store = id;
    if (this.filter_by_store !== "all") {
      for (let i of this.products) {
        for (let y of i.stores) {
          if (y.store_id === this.filter_by_store) {
            i.visible = true;
            i.v_stock = y.stock;
            break;
          } else {
            i.visible = false;
          }
        }
      }
    }

    for(let i in this.storesAvailables) {
      if(this.storesAvailables[i].store_id === id) {
        this.id_store = Number(i);
      }
    }
    this.ch.detectChanges();
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000
    });
    toast.present();
  }


  setCant(cant, add, s1, s2) {
    let input: any = document.getElementById('cantTo' + cant);
    if(add) {
      input.value = (Number(input.value) + 1).toString();

      if (this.filter_by_store === "all") {
        if(Number(input.value) >= s2) {
          input.value = (s2).toString();
        }
      } else {
        if(Number(input.value) >= s1) {
          input.value = (s1).toString();
        }
      }

    } else {
      input.value = (Number(input.value) - 1).toString();

      if(Number(input.value) <= 0) {
        input.value = "1";
      }
    }
  }

  removeProductItem(cant) {
    let input: any = document.getElementById('cantTo' + cant);

    for (let i in this.bags[this.selectedBag].items) {
      if(this.products[cant].prd_cod === this.bags[this.selectedBag].items[i].reference) {
        this.bags[this.selectedBag].items[i].c -= Number(input.value);
        if (this.bags[this.selectedBag].items[i].c <= 0) {
          this.bags[this.selectedBag].items[i].c = 1;
        }
        break
      }
    }

    this.setReceiptPrices();
  }

  addProduct(cant, s,) {
    console.log(cant, s, this.products);
    let input: any = document.getElementById('cantTo' + cant);
    // console.log(this.products[cant], "to bag");

    let contain = false;
    for (let i in this.bags[this.selectedBag].items) {
      if(this.products[cant].prd_cod === this.bags[this.selectedBag].items[i].reference) {
        if (this.bags[this.selectedBag].items[i].c + Number(input.value) > Number(s)) {
          this.presentToast("No hay suficiente stock!");
        } else {
          this.bags[this.selectedBag].items[i].c += Number(input.value);
        }
        contain = true;
        break;
      }
    }

    if(!contain) {
      if (Number(input.value) > Number(s)) {
        this.presentToast("No hay suficiente stock!");
      } else {
        this.bags[this.selectedBag].items.push(
          {
            c: Number(input.value), 
            name: this.products[cant].prd_nom, 
            value: this.products[cant].prd_sale_prices[0].value,
            profit: this.products[cant].prd_sale_prices[0].profit,
            cost: Number(this.products[cant].prd_cost),
            reference: this.products[cant].prd_cod
          });
      }
    }
    this.setReceiptPrices();
  }

  removeProduct(i) {
    this.bags[this.selectedBag].items = this.removeElementAtIndex(this.bags[this.selectedBag].items, i);
    this.setReceiptPrices();
  }

  setReceiptPrices() {
    this.totalPriceBag = 0; this.subPriceBag = 0; this.taxesBag = 0;
    for (let i of this.bags[this.selectedBag].items) {
      this.totalPriceBag += i.value * i.c;
    }

    const resultado = this.cIva(this.totalPriceBag, 19);
    this.subPriceBag = Number(resultado.subtotal.toFixed(2));
    this.taxesBag = Number(resultado.iva.toFixed(2));
  }

  clearBag() {
    this.totalPriceBag = 0; this.subPriceBag = 0; this.taxesBag = 0;
    this.bags[this.selectedBag].items = [];
  }

  pickClient(data) {
    this.bags[this.selectedBag].client = data;
    this.sugestClients = [];
  }

  searchClient(text) {
    let client = text.detail.value;
    if(client !== "") {
      this.sugestClients = this.filterClints(client);
    } else {
      this.sugestClients = [];
    }
  }

  pickReceiptType(a, i) {
    this.typeReceipt = i;
    for(let tp in a) {
      if(tp !== i.toString()) {
        a[tp].checked = false;
      } else {
        a[tp].checked = true;
        this.getReceiptType(this.receipts[tp]);
      }
    }
    this.saveImage = false; this.printImage = false; this.sendWhatsapp = false;
  }

  setReceiptState(el, key) {
    if(key === "pending") {
      this.pendingReceipt = el.checked;
      this.storage.set(key, this.pendingReceipt);
    } else if(key === "method") {
      this.payMethod = el.checked;
      this.storage.set(key, this.payMethod);
    } else if(key === "showqr") {
      this.showDataReceipt = el.checked;
      this.storage.set(key, this.showDataReceipt);
    } else if(key === "watermark") {
      this.waterMark = el.checked;
      this.storage.set(key, this.waterMark);
    }
  }

  getReceiptState() {
    this.storage.get("pending").then((rs) => {
      if (rs !== null || rs != undefined) {
        this.pendingReceipt = rs;
      }
    });
    this.storage.get("method").then((rs) => {
      if (rs !== null || rs != undefined) {
        this.payMethod = rs;
      }
    });
    this.storage.get("showqr").then((rs) => {
      if (rs !== null || rs != undefined) {
        this.showDataReceipt = rs;
      }
    });
    this.storage.get("watermark").then((rs) => {
      if (rs !== null || rs != undefined) {
        this.waterMark = rs;
      }
    });
  }

  async setReceipt() {
    this.getReceiptType(this.receipts[this.typeReceipt]);

    const div = this.receiptSC.nativeElement;
    const loading = await this.loadingController.create({
      message: 'Finalizando venta...',
      spinner: 'bubbles'
    });
    await loading.present();

    html2canvas(div, {scale: 1.2}).then(canvas => { //IMAGE QUALITY
      const imagenBase64 = canvas.toDataURL('image/png');
      loading.dismiss();
      if (this.bags[this.selectedBag].client.name === "") {
        this.bags[this.selectedBag].client.name = "Mostrador"
      }
      console.log(this.bags[this.selectedBag]);
      
      this.storage.get("token").then(async (tk) => {
        let postData: any = {
          token: tk,
          subtotal: this.subPriceBag,
          total: this.totalPriceBag,
          taxes: this.taxesBag,
          client_id : 0, ///???
          sb_store_id: this.id_store,
          detail: this.bags[this.selectedBag].items,
          client_detail: this.bags[this.selectedBag].client,
          image_receipt: imagenBase64,
          type: this.receipts[this.typeReceipt],
          consecutive: Number(this.refReceipt),
          trace: this.trace
        };

        const data = JSON.stringify(postData);

        const loading = await this.loadingController.create({
          message: 'Finalizando venta...',
          spinner: 'bubbles'
        });
        await loading.present();

        this.httpParser.dcpayload(data, true)
          .then((postToSend: any) => {
            this.http.post(postToSend?.url + 'receipts/set_receipt.php',
            {
              data: postToSend?.value
            }, 
            {responseType: 'json'}
            ).pipe(finalize(() => {loading.dismiss()})).subscribe((data: any) => {
              if (data?.error) {
                if (data.error === '1-0') {
                  this.presentToast('Error al guardar producto.');
                }
              } else if (data?.data) {
                this.httpParser.dcpayload(data.data, false).then((response: any) => {
                  if (response.data) {
                    console.log(response.img); //wh
                    this.ch.detectChanges();
                    this.confAlt = false;
                    if (this.saveImage) {
                      this.saveBase64Image(imagenBase64, this.refReceipt);
                    }
                    if (this.printImage) {
                      this.printImageBase64(response.img);
                    }

                    let efectivo: any = document.getElementById("efectivo");
                    efectivo.value = "";

                    this.valuePayList = 0; this.payList = [];
                    this.bags  = [{client: {name: "", cc: "", tel: "", dir: ""}, items: []}];
                    this.totalPriceBag = 0; this.refReceipt = "Pendiente";
                    this.presentToast("Documento guardado");

                    this.loadProducts();
                  } else if(response.error) {
                    this.presentToast('No tienes permisos para ver esta información');
                  }
                }).catch(err => {
                  this.presentToast('Error: No se ha podido cargar la información');
                  loading.dismiss();
                });
              }
            }, err => {
              if (err.error === '1-0') {
                this.presentToast('No tienes permisos para ver esta información');
              }
              console.log(JSON.stringify(err));
            });
          });
      });
    });
  }
  // calculations ans filters

  filterClints(texto: string): any[] {
    const normalizedText = texto.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    const result = this.clients.filter(client => {
      const normalizedName = client.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      const idCNormalized= client.identify.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      const idCphone= client.phone_number.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

      return normalizedName.includes(normalizedText) || idCNormalized.includes(normalizedText)  || idCphone.includes(normalizedText);
    });
  
    return result;
  }

  removeElementAtIndex<T>(array: T[], index: number): T[] {
    array.splice(index, 1);
    return array;
  }

  cIva(total: number, porcentajeIVA: number): { subtotal: number, iva: number } {
    const subtotal = total / (1 + (porcentajeIVA / 100));
    const iva = total - subtotal;
    return { subtotal, iva };
  }

  getKey(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  // adding client
  setDateTime(event) {
    console.log(event);
    this.b_d = event.detail.value;
    this.birth_date =  event.detail.value.split("T")[0];
  }

  async createClient() {
    if(this.birth_date === "") {
      this.presentToast("Seleccione una fecha de nacimiento");
    } else {
      let ct = true;
      for (let i of this.siginForm) {
        if (i.value === "") {
          ct = false;
        }
      }

      if (ct) {
        this.presentToast("Guardando...");
        this.storage.get("token").then( async (tk) => {
          let postData: any = {
            adding: true,
            name: this.siginForm[0].value,
            email: this.siginForm[2].value,
            identify: this.siginForm[1].value, //
            pass: this.siginForm[1].value,
            birthdate: this.b_d,
            phone_number: this.siginForm[3].value,
            dir: this.siginForm[4].value, //
            type: {tags: "client"},
            token: tk,
            avatar_url: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
            stores: {auth: [this.id_store]}
          };

          const data = JSON.stringify(postData);

          const loading = await this.loadingController.create({
            message: 'Creando cliente...',
            spinner: 'bubbles'
          });
          await loading.present();
          
          this.httpParser.dcpayload(data, true)
          .then((postToSend: any) => {
            this.http.post(postToSend?.url + 'accounts/add_client.php',
            {
              data: postToSend?.value
            }, 
            {responseType: 'json'}
            ).pipe(finalize(() => {loading.dismiss()})).subscribe((data: any) => {
              if (data?.error) {
                this.presentToast(data?.error);
              } else if (data?.data) {
                this.httpParser.dcpayload(data.data, false).then((response: any) => {
                  if (response.data) {
                    this.addingAccount = false;
                    this.presentToast('Cliente creado con éxito');
                    this.bags[this.selectedBag].client.name = this.siginForm[0].value;
                    this.bags[this.selectedBag].client.dir = this.siginForm[4].value;
                    this.bags[this.selectedBag].client.tel = this.siginForm[3].value;
                    this.bags[this.selectedBag].client.id_c = this.siginForm[1].value;
                    this.addingAccount = false;
                  } else {
                    this.presentToast('Error: El Cliente no ha sido guardado');
                  }
                }).catch(err => this.presentToast('Error: No se ha podido registrar'));
              }
            }, err => {
              loading.dismiss();
              if (err.error === '1-0') {
                this.presentToast('Esta dirección de Email ya está en uso');
              }
            });
          });
        });
      } else {
        this.presentToast("Debe llenar todo el formulario antes de guardar");
      }
    }
  }

  async loadAccounts() {
    this.storage.get("token").then(async (tk) => {
      let postData: any = {
        token: tk,
      };

      const data = JSON.stringify(postData);
      
      const loading = await this.loadingController.create({
        message: 'Buscando clientes...',
        spinner: 'bubbles'
      });
      await loading.present();

      this.httpParser.dcpayload(data, true)
        .then((postToSend: any) => {
          this.http.post(postToSend?.url + 'accounts/get_clients.php',
          {
            data: postToSend?.value
          }, 
          {responseType: 'json'}
          ).pipe(finalize(() => {loading.dismiss()})).subscribe((data: any) => {
            if (data?.error) {
              if (data.error === '1-0') {
                this.presentToast('Error al guardar producto.');
              }
            } else if (data?.data) {
              this.httpParser.dcpayload(data.data, false).then((response: any) => {
                if(response.token) {
                  this.storage.set("token", response.token);
                  // susesfully
                  this.clients = JSON.parse(response.data);
                  this.ch.detectChanges();
                } else if(response.error) {
                  this.presentToast('No tienes permisos para ver esta información');
                }
              }).catch(err => {
                this.presentToast('Error: No se ha podido cargar la información'); loading.dismiss();
              });
            }
          }, err => {
            if (err.error === '1-0') {
              this.presentToast('No tienes permisos para ver esta información');
            }
            console.log(JSON.stringify(err));
          });
        });
    });
  }

  updateForm(index: any, event: any) {
    this.siginForm[index] = Object.assign(this.siginForm[index], {value: event.detail.value});
  }

  getReceiptType(tp) {
    console.log(this.storesAvailables[this.id_store]);

    this.storage.get("token").then((tk) => {
      let postData: any = {
        token: tk,
        receipt_type: tp,
        store: this.id_store
      };
      const data = JSON.stringify(postData);

      this.httpParser.dcpayload(data, true)
        .then((postToSend: any) => {
          this.http.post(postToSend?.url + 'receipts/get_receipt_type.php',
          {
            data: postToSend?.value
          }, 
          {responseType: 'json'}
          ).pipe(finalize(() => {})).subscribe((data: any) => {
            if (data?.error) {
              if (data.error === '1-0') {
                this.presentToast('Error al guardar producto.');
              }
            } else if (data?.data) {
              this.httpParser.dcpayload(data.data, false).then((response: any) => {
                if(response.token) {
                  this.storage.set("token", response.token);
                  this.refReceipt = this.numberToRef(response.value + 1);
                  this.trace = this.getKey(32);
                  this.receiptTrace = this.dataReceipt + this.trace;
                  this.ch.detectChanges();
                } else if(response.error) {
                  this.presentToast('No tienes permisos para ver esta información');
                }
              }).catch(err => {
                this.presentToast('Error: No se ha podido cargar la información');
              });
            }
          }, err => {
            if (err.error === '1-0') {
              this.presentToast('No tienes permisos para ver esta información');
            }
            console.log(JSON.stringify(err));
          });
        });
    });
  }

  numberToRef(numero: number): string {
    let numeroTexto = numero.toString();
    if (numeroTexto.length < 5) {
      const cerosFaltantes = 5 - numeroTexto.length;
      numeroTexto = '0'.repeat(cerosFaltantes) + numeroTexto;
    }
    return numeroTexto;
  }

  saveBase64Image(base64, name) {
    let img: any = document.getElementById("save_image");
    img.href = base64;
    img.setAttribute("download", name + ".jpg");
    img.click();
  }

  // async saveBase64Image(base64, name) {
  //   try {
  //     // Crear un nuevo documento PDF
  //     const pdfDoc = await PDFDocument.create();
  //     // Añadir una nueva página al documento
  //     const page = pdfDoc.addPage();
  //     // Decodificar la imagen base64 y añadirla a la página
  //     const jpgImage = await pdfDoc.embedJpg(base64);
  //     const { width, height } = jpgImage.scale(1);
  //     page.drawImage(jpgImage, {
  //       x: 0,
  //       y: 0,
  //       width: width,
  //       height: height,
  //     });
  //     // Convertir el documento PDF a un ArrayBuffer
  //     const pdfBytes = await pdfDoc.save();
  //     // Convertir el ArrayBuffer a un Blob
  //     const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
  //     // Crear un enlace para descargar el PDF
  //     const pdfUrl = URL.createObjectURL(pdfBlob);
  //     const link = document.createElement("a");
  //     link.href = pdfUrl;
  //     link.setAttribute("download", name + ".pdf");
  //     link.click();
  //   } catch (error) {
  //     console.error("Error al guardar la imagen como PDF:", error);
  //   }
  // }

  printImageBase64(url) {
    this.storage.set("image-receipt", url).then(() => {
      this.router.navigate(['printreceipt']);
    });
  }

  payButton(ef) {
    this.valuePayList = Number(ef.value);
    for(let h of this.payList) {
      if(h.pay !== undefined) {
        this.valuePayList += h.pay;
      } else {
        h.pay = 0;
      }
    }

    if (this.valuePayList >= this.totalPriceBag) {
      this.confAlt = true; 
      this.getReceiptType('receipt');
    } else if (this.valuePayList < this.totalPriceBag) {
      this.presentToast("El pago es insuficiente");
    } else {
      this.presentToast("Debes indicar el método de pago");
    }
  }

  addToPayList(pay) {
    let add = true;
    for (let i of this.payList) {
      if (i.name === pay.name && i.value === i.value) {
        this.presentToast("El método de pago ya fué agregado");
        add = false;
        break;
      }
    }

    if(add) {
      this.payList.push(pay);
    }
  }

  setPayList(ev, i, ef) {
    this.payList[i].pay = Number(ev.detail.value);
    this.valuePayList = Number(ef.value);
    for(let h of this.payList) {
      if(h.pay !== undefined) {
        this.valuePayList += h.pay;
      } else {
        h.pay = 0;
      }
    }
  }

  removePayMethod(id) {
    this.payList = this.removeIndex(this.payList, id);
  }

  removeIndex<T>(array: T[], index: number): T[] {
  if (index < 0 || index >= array.length) {
      throw new Error("Índice fuera de los límites del array");
  }
  return array.slice(0, index).concat(array.slice(index + 1));
}
}

