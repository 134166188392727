import { ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { MenuController, ToastController } from '@ionic/angular';
import SwiperCore, { Autoplay, Keyboard, Pagination, Scrollbar, Zoom, Navigation } from 'swiper';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { HttpParserService } from '../services/http-parser/http-parser.service';
import { InventoryComponent } from '../components/inventory/inventory.component';
import { UsersComponent } from '../components/users/users.component';
import { StoreComponent } from '../components/store/store.component';
import { SalesComponent } from '../components/sales/sales.component';
import { ChartLinesComponent } from '../components/chart-lines/chart-lines.component';
import { ClientsComponent } from '../components/clients/clients.component';

SwiperCore.use([Autoplay, Keyboard, Pagination, Scrollbar, Zoom, Navigation]);

@Component({
  selector: 'app-editor',
  templateUrl: './editor.page.html',
  styleUrls: ['./editor.page.scss'],
})
export class EditorPage implements OnInit {
  @ViewChild(UsersComponent) usersComponent: UsersComponent;
  @ViewChild(InventoryComponent) inventoryComponent: InventoryComponent;
  @ViewChild(StoreComponent) storeComponent: StoreComponent;
  @ViewChild(SalesComponent) salesComponent: SalesComponent;
  @ViewChild(ChartLinesComponent) chartComponent: ChartLinesComponent;
  @ViewChild(ClientsComponent) clientComponent: ClientsComponent;




  public storeSelect = "Ver todos";
  public storeSelectid = "";
  storesAvailables: any = [];

  menuListEnabled = false; menuListEnabledTime = true;
  statsEnable = false;

  buttonsMenu = [
    {id: 'store', icon: 'storefront-outline', text: 'Caja', class: 'border-row'},
    {id: 'sales', icon: 'pricetags-outline', text: 'Ventas', class: 'border-row-off'},
    // {id: 'reports', icon: 'receipt-outline', text: 'Reportes', class: 'border-row-off'},
    {id: 'inventory', icon: 'cube-outline', text: 'Inventario', class: 'border-row-off'},
    {id: 'clients', icon: 'people-circle-outline', text: 'Clientes', class: 'border-row-off'},
    {id: 'users', icon: 'people-outline', text: 'Usuarios', class: 'border-row-off'},
    // {id: 'edition', icon: 'color-palette-outline', text: 'Edición', class: 'border-row-off'}, 
    // {id: 'apps', icon: 'apps-outline', text: 'Apps', class: 'border-row-off'},
    // {id: 'settings', icon: 'settings', text: 'Configuración', class: 'border-row-off'},
    // {id: 'help', icon: 'help-circle', text: 'Ayuda', class: 'border-row-off'},
    // {id: 'help', icon: 'chatbox-ellipses', text: 'Soporte', class: 'border-row-off'},
  ];

  buttonsSettings = [
    {id: 'settings', icon: 'settings-outline', text: 'Configuración', class: 'border-row-off'},
    {id: 'help', icon: 'help-circle-outline', text: 'Ayuda', class: 'border-row-off'},
    {id: 'help', icon: 'chatbox-ellipses-outline', text: 'Soporte', class: 'border-row-off'},
  ];
  
  buttonsMenuSelect = 0;
  classItem1 = 'center item1-open';
  classItem2 = 'left item2-open';
  tabSelect = 'store';
  tabIndex = 0;

  gStSelection = 0;
  constructor(
    private menuController: MenuController,
    private storage: Storage,
    private router: Router,
    private httpParser: HttpParserService,
    private toastController: ToastController,
    private cdr: ChangeDetectorRef
    ) {}

  ngOnInit() {
    this.menuController.enable(false);
    this.storage.create();
  }

  ngAfterViewInit() {
    this.storage.get("token").then((result) => {
      if (result) {
        this.httpParser.getToken(result).then((auth) => {
          if (!auth) {
            this.router.navigate(["/login/users"]);
            console.log("Error Auth");
          } else {
            console.log("Sucess Auth");
          }
        }).catch(err => 
          {
            this.router.navigate(["/login/users"])
            console.log(err, "Error auth");
          });
      } else {
        this.router.navigate(["/login/users"]);
        console.log("Error to get token");
      }
    }).catch(err => this.router.navigate(["login/users"]));

    this.storage.get("stores").then((result) => {
      this.storesAvailables = JSON.parse(result);
      this.storeSelectid = this.storesAvailables[0].store_id;
      this.selectStore(this.storesAvailables[0].store_name, this.storesAvailables[0].store_id);
    }).catch(err => this.router.navigate(["login/users"]));
  }

  selectMenu(index, buttonId) {
    this.buttonsMenu[this.buttonsMenuSelect].class = 'border-row-off';
    this.buttonsMenu[index].class = 'border-row';
    this.buttonsMenuSelect = index;

    if(index > this.tabIndex) {
      document.getElementById(this.tabSelect).className = 'conten-components-btns ccb-close';
      document.getElementById(buttonId).className = 'conten-components-btns ccb-open';
    } else {
      document.getElementById(this.tabSelect).className = 'conten-components-btns ccb-close-up';
      document.getElementById(buttonId).className = 'conten-components-btns ccb-open-up';
    }

    this.tabSelect = buttonId;
    this.tabIndex = index;

    if(buttonId === "inventory") { //consultar datos de componente
      this.inventoryComponent.loadProducts(this.storeSelectid);
    } else if (buttonId === "users") {
      this.usersComponent.loadAccounts();
    } else if (buttonId === "store") {
      this.storeComponent.loadProducts();
    } else if (buttonId === "sales") {
      this.salesComponent.loadSales();
    } else if (buttonId === "reports") {
      this.inventoryComponent.loadProducts(this.storeSelectid);
      // this.chartComponent.loadStatics();
    } else if (buttonId === "clients") {
      this.clientComponent.loadClients();
    }
  }

  menu() {
    if(this.menuListEnabledTime) {
      this.menuListEnabledTime = false;
      let menuBtn = document.getElementById('icon-menu');
      let menuEd = document.getElementById('menu-edit');
      let shadow = document.getElementById('sh-menu-edit');
      
      if(this.menuListEnabled) {
        // menuBtn.className = 'icon-menu-button md hydrated close-anim-menu-button';
        menuEd.className = 'menu-ed close-anim-menu-ed';
        shadow.className = 'shadow-background-ed sh-bc';
        // rowContent.className = 'row-editor close-anim-row md hydrated';
        // this.classItem1 = 'center item1-close';
        // this.classItem2 = 'left item2-close';
        this.menuListEnabled = false;
      } else {
        // menuBtn.className = 'icon-menu-button md hydrated open-anim-menu-button';
        menuEd.className = 'menu-ed open-anim-menu-ed';
        shadow.className = 'shadow-background-ed sh-bo';
        // rowContent.className = 'row-editor open-anim-row md hydrated';
        // this.classItem1 = 'center item1-open';
        // this.classItem2 = 'left item2-open';
        this.menuListEnabled = true;
      }

      setTimeout(() => {
        this.menuListEnabledTime = true;
      }, 500);
    }
  }

  showNamePop(popover) {
    if(!this.menuListEnabled) {
      console.log(popover);
      document.getElementById(popover).className = 'pop-name pop-name-show';
    }
  }

  hidePop(popover) {
    if(!this.menuListEnabled) {
      console.log(popover);
      document.getElementById(popover).className = 'pop-name pop-name-hide';
    }
  }

  selectButtonItem(id) {
    if(id === 'btn-stats-invs') {
      this.statsEnable = true;
    } else {
      this.statsEnable = false;
    }
    document.getElementById(id).click();
  }

  selectStore(storeValue, id) {
    if(storeValue === 'all') {
      this.storeSelect = 'Ver todos';
      this.storeSelectid = "all";
      this.inventoryComponent.selectStore("all");
      this.storeComponent.selectStore("all");
      this.salesComponent.selectStore("all");
      this.clientComponent.selectStore("all");
    } else {
      this.storeSelect = storeValue;
      this.storeSelectid = id;
      this.inventoryComponent.selectStore(id);
      this.storeComponent.selectStore(id);
      this.salesComponent.selectStore(id);
      this.clientComponent.selectStore(id);
    }

    this.storage.set("store-selected", storeValue);
    this.createCharts(this.gStSelection);
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000
    });
    toast.present();
  }

  createCharts(i) {
    this.gStSelection = i;
    this.inventoryComponent.generateCharts(i);
  }

  createUsers() {
    document.getElementById("us-c-create").className = "btn-green md button button-clear in-toolbar button-has-icon-only ion-activatable ion-focusable hydrated ion-activated"; 
    document.getElementById("us-c-show").className = "btn-gray md button button-clear in-toolbar button-has-icon-only ion-activatable ion-focusable hydrated ion-activated"; 
    
    this.usersComponent.addingAccount = true;
    this.usersComponent.detectChanges();
  }

  showUsers() {
    document.getElementById("us-c-create").className = "btn-gray md button button-clear in-toolbar button-has-icon-only ion-activatable ion-focusable hydrated ion-activated"; 
    document.getElementById("us-c-show").className = "btn-green md button button-clear in-toolbar button-has-icon-only ion-activatable ion-focusable hydrated ion-activated"; 

    this.usersComponent.addingAccount = false;
    this.usersComponent.detectChanges();
  }
}
