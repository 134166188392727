import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { LenguagesService } from './services/languages/lenguages.service';
import { ThemeService } from './services/theme/theme.service';
import { initializeApp } from "firebase/app";
import { firebaseConfig } from 'src/environments/environment';
import { getFirestore, addDoc, collection, setDoc, doc, getDoc, where, query, getDocs, collectionGroup} from "firebase/firestore";
import { Title } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient} from '@angular/common/http';
import { finalize} from 'rxjs/operators';
//import { getAnalytics } from "firebase/analytics";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{
  idFilterSelected = 'all';
  db : any;
  public favicon = '';
  enbMobile = false;
  constructor(
    private lenguages: LenguagesService,
    private theme: ThemeService, 
    private menuController: MenuController,
    private title: Title,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private http: HttpClient
    ) {}
  
    ngOnInit(): void {
    this.menuController.enable(false);
    const app = initializeApp(firebaseConfig);
    this.db = getFirestore(app);
    this.getCategories();
    this.getSettings();
    this.getProducts();
    //const analytics = getAnalytics(app);
  }

  async putDatatbase() {
    const store = collection(this.db, "store");
    // await setDoc(doc(store, "settings"), 
    // {
    //   options: {
    //     name: "Custom Store",
    //     favicon: "https://www.gstatic.com/devrel-devsite/prod/v99fd0c828fa12c8e4ac5a683d534aa207353ab3b49583792c0a838c031276d00/firebase/images/touchicon-180.png",
    //     license: {
    //       block: '44444',
    //       permission: '33333',
    //       hash: 'asdas5dasd4a564sd546a5s4d645asd'
    //     },
    //     theme: {
    //       favicon: 'https://ssss',
    //       background: '#f0f0f0;',
    //       imageMenu: 'https://cdn.pixabay.com/photo/2017/07/20/17/30/chandelier-2523029_960_720.png',
    //       imageMenuWebp: 'https://cdn.pixabay.com/photo/2017/07/20/17/30/chandelier-2523029_960_720.png',
    //       styleIcons: '-outline',
    //       menu: {
    //         primaryColor: '#ff9000;',
    //         primaryColorRgb: '255, 144, 0;',
    //         primaryContrast: '#ffffff;',
    //         primaryContrastRgb: '255, 255, 255;',
    //         primaryShade: '#ffa300;',
    //         primaryTint: '#ffb000;',
    //         primaryColorSelectOpacity: '#ff900020;',
    //       },
    //       header: {
    //         headerColor: '#ffffff;',
    //         menuButton: '#ff9000;',
    //         cartButton: '#ff9000;',
    //         badger: {
    //           background: '#ff9000;',
    //           text: '#ffffff;'
    //         },
    //         searchbar: {
    //           color: '#ff9000;',
    //           colorRgb: '255, 144, 0;',
    //           contrast: '#ffffff;',
    //           contrastRgb: '255, 255, 255;',
    //           shade: '#ffa300;',
    //           tint: '#ffb000;',
    //           radius: '3px;'
    //         }
    //       },
    //       card: {
    //         color: '#ffffff;',
    //         colorRgb: '255, 144, 0;',
    //         contrast: '#ffffff;',
    //         contrastRgb: '255, 255, 255;',
    //         shade: '#ffa300;',
    //         tint: '#ffb000;',
    //         image: '#ffffff;',
    //         mid: '#ffffff;',
    //         price: '#000000;',
    //         dcto: '#ffffff;',
    //         dctoPercent: 'green;',
    //         description: '#000000',
    //         item: {
    //           color: '#ff9000;',
    //           colorRgb: '255, 144, 0;',
    //           contrast: '#ffffff;',
    //           contrastRgb: '255, 255, 255;',
    //           shade: '#ffa300;',
    //           tint: '#ffb000;',
    //         }
    //       }
    //     }
    //   }
    // });


    await addDoc(collection(store, "inventory", 'products'), 
    {
      name: 'aasa', id: '77854', price: 3000, 
      description: 'Cinta negra marca Orion para cableado',
      features: [{title: 'Marca', text: 'Orion'}, {title: 'Tamaño', text: 'Grande'}, {title: 'Ancho', text: '2cm'}],
      stock: 10,
      offer: 2500,
      barcode: '000000',
      qrcode: '000000',
      _payments: ['Mercadopago', 'Efecty', 'Bitcoin', 'Paypal'],
      images: ['https://asdasdadasdsad', 'https://asdasdadasdsad', 'https://asdasdadasdsad', 'https://asdasdadasdsad', 'https://asdasdadasdsad'],
      tags:['Cinta', 'Ferretería', 'Pegar'],
      categories: ['Cintas'] 
    });

    // await addDoc(collection(store, "inventory", 'products'), 
    // {
    //   name: 'Ladrillo', id: '77851', price: 3000, stock: 10,
    //   _description: true, description: 'Cinta negra marca Orion para cableado',
    //   _features: true, features: [{title: 'Marca', text: 'Orion'}, {title: 'Tamaño', text: 'Grande'}, {title: 'Ancho', text: '2cm'}],
    //   _offer: true, offer: 2500,
    //   _barcode: true, barcode: '000000',
    //   _qrcode: true, qrcode: '000000',
    //   _payments: ['Mercadopago', 'Efecty', 'Bitcoin', 'Paypal'],
    //   images: ['https://asdasdadasdsad', 'https://asdasdadasdsad', 'https://asdasdadasdsad', 'https://asdasdadasdsad', 'https://asdasdadasdsad'],
    //   tags:['Cinta', 'Ferretería', 'Pegar'],
    //   categories: ['Cintas'] 
    // });

    // await setDoc(doc(store, "segmentation"), 
    // {
    //   categories: [
    //     {
    //       name: 'Cintas', icon: 'star'
    //     }, 
    //     {
    //       name: 'Carros', icon: 'car-sport'
    //     }
    //   ]
    // });

    // try {
    //   const docRef = await addDoc(collection(this.db, "stores"), {
    //     mystore:
    //     {
    //       name: 'Custom Store'
    //     }
    //   });
    //   console.log("Document written with ID: ", docRef.id);
    // } catch (e) {
    //   console.error("Error adding document: ", e);
    // }
  }

  async getSettings() {
    const docRef = doc(this.db, "store", 'settings');
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      this.theme.setSettings(docSnap.data());
      //this.title.setTitle(docSnap.data().options.name);
      //this.setFavicon(docSnap.data().options.favicon);
      this.favicon = docSnap.data().options.favicon;
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  }

  async getCategories() {
    const docRef = doc(this.db, "store", 'segmentation');
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      this.lenguages.setCategories(docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  }

  public async getProducts(){ //llamada dependiendo de la exposición
    const items = query(collectionGroup(this.db, 'indices'), where('id', '!=', '1'));
    const querySnapshot = await getDocs(items);
    let data: any = [];
    querySnapshot.forEach((doc) => {
        data.push(doc.data())
    });
    return data;
  } 

  public async getProductsTop(){ //llamada dependiendo de la exposición
    const items = query(collectionGroup(this.db, 'top'), where('id', '!=', '1'));
    const querySnapshot = await getDocs(items);
    let data: any = [];
    querySnapshot.forEach((doc) => {
        data.push(doc.data())
    });
    return data;
  } 

  public async callProduct(id: string){ //llamada por id al ver details
    const items = query(collectionGroup(this.db, 'products'), where('id', '==', id));
    const querySnapshot = await getDocs(items);
    let data: any;
    querySnapshot.forEach((doc) => {
      data = doc.data();
    });
    return data;
  } 

  public async callSearch(name, type){ //llamada por id al ver details
    return await new Promise((resolve, reject) => {
      this.http.post('https://us-central1-custom-store-86c8e.cloudfunctions.net/getSearch', {
        search: name,
        type: type
      }, {responseType: 'json'}
      ).pipe(finalize(() => {})).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  } 


  setFavicon(favicon) {
    var link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = favicon;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  filters(){
    let content: any = document.getElementById('filter-container');
    if(content.className === 'filter-items filters-animation-open'){
      content.className = 'filter-items filters-animation-close';

      document.getElementById('filter-button').setAttribute('style', this.theme.setFilterOff());
      document.getElementById('filter-icon').setAttribute('style', this.theme.setFilterIconOff());

    } else {
      content.className = 'filter-items filters-animation-open';
      document.getElementById('filter-button').setAttribute('style', this.theme.setFilterOn());
      document.getElementById('filter-icon').setAttribute('style', this.theme.setFilterIconOn());
    }
  }

  selectFilter(id){
    this.closeFilters();
    this.idFilterSelected = id;
    this.menuController.close();
  }

  closeFilters(){
    let content: any = document.getElementById('filter-container');
    if(content.className === 'filter-items filters-animation-open'){
      content.className = 'filter-items filters-animation-close';  
      document.getElementById('filter-button').setAttribute('style', this.theme.setFilterOff());
      document.getElementById('filter-icon').setAttribute('style', this.theme.setFilterIconOff());
    }
    setTimeout(() => {
      content.className = 'filter-items'
    }, 400);
  }
}
