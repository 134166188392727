import { Injectable } from '@angular/core';
import * as CryptoJS from "crypto-js";
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpParserService {
  private _jsonURL = 'assets/credentials/credentials.json';
  private _dbURL = '';
  public credentials: any;
  constructor(private http: HttpClient) { }

  public getCredentials(): Observable<any> {
    return this.http.get(this._jsonURL);
  }

  public async dcpayload  (data: any, crypt: boolean) {
    return await new Promise((resolve, reject) => {
      this.getCredentials().subscribe(dataK => {
        let ks = new Date();
        const dia = this.numberValue(ks.getDate());
        const mes = this.numberValue(ks.getMonth() + 1);
        const minuto = this.numberValue(ks.getMinutes());
        const anio = ks.getFullYear().toString();
        let mth = (`${dia}-${mes}-${minuto}-${anio}`);

        this.credentials = CryptoJS.SHA256(mth).toString();
        const key = CryptoJS.enc.Utf8.parse(this.credentials.substring(0, 32));
        // console.log(this.credentials.substring(0, 32));
        this._dbURL = dataK?.url;
        if(!crypt) {
          const ivR = this.credentials.substring(33, 49);
          const iv = CryptoJS.enc.Utf8.parse(ivR);
          let decrypted = CryptoJS.AES.decrypt(data, key, { iv: iv });
          let result = decrypted.toString(CryptoJS.enc.Utf8);
          const jsonData =  JSON.parse(result);
          resolve(jsonData);
        } else { // to send
          const ivR = this.credentials.substring(33, 49);
          const iv = CryptoJS.enc.Utf8.parse(ivR);
          let encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv });
          let redsencrypted = encrypted.toString(CryptoJS.format.OpenSSL);
          // redsencrypted = btoa(redsencrypted);
          resolve({value: redsencrypted, url: this._dbURL});
        }
      });
    });
  }

  createString(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      result += characters.charAt(randomIndex);
    }
    return result;
  }

  numberValue(value: any) {
    let vl = value.toString();
    if(vl.length === 1) {
      vl = "0" + vl;
    }
    return vl;
  }


  async getToken(token: any) {
    return await new Promise((resolve, reject) => {
      this.getCredentials().subscribe(dataK => {
        let send = {token: token}
        this.dcpayload(JSON.stringify(send), true).then((datacr: any) => {
          this.http.post(dataK?.url + 'auth/get_token.php',
          {
            data: datacr?.value
          },
          {responseType: 'json'}
          ).pipe(finalize(() => {})).subscribe((data: any) => {
            if (data.data) {
              this.dcpayload(data.data, false).then((response: any) => {
                if (response?.success) {
                  resolve(true);
                } else {
                  reject(true);
                }
              })
            } else {
              reject("Corrupted data");
            }
          }, err => { 
            reject(err);
          });
        });
      });
    });


    
  }
}
