import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'Store/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'product-id/:id',
    loadChildren: () => import('./product-id/product-id.module').then( m => m.ProductIdPageModule)
  },
  {
    path: 'editor-web',
    loadChildren: () => import('./editor/editor.module').then( m => m.EditorPageModule)
  },
  {
    path: 'login/:id',
    loadChildren: () => import('./editor-login/editor-login.module').then( m => m.EditorLoginPageModule)
  },
  {
    path: 'printreceipt',
    loadChildren: () => import('./printreceipt/printreceipt.module').then( m => m.PrintreceiptPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'demo',
    loadChildren: () => import('./demo/demo.module').then( m => m.DemoPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
