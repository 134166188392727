import { AfterViewInit, Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { ToastController } from '@ionic/angular';
import { HttpParserService } from 'src/app/services/http-parser/http-parser.service';
import { HttpClient } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-chart-lines',
  templateUrl: './chart-lines.component.html',
  styleUrls: ['./chart-lines.component.scss'],
})
export class ChartLinesComponent implements OnInit {
  @ViewChild('staticsCanvas') private lineCanvas: ElementRef;
  lineChart: any;

  globalStatics = [
    {label: "Productos", data: [0, 2, 0, 5, 0, 12, 14, 0, 5, 0, 7, 0]},
    {label: "Stock", data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]},
    {label: "Capital", data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]},
    {label: "Ventas", data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]},
    {label: "Utilidad", data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]},
  ]
  
  globalItems = 0; 
  globalStock = 0; 
  globalCapital = 0;

  products: any = [];
  constructor(
    private ch: ChangeDetectorRef,
    private toastController: ToastController,
    private httpParser: HttpParserService,
    private http: HttpClient,
    private storage: Storage
  ) { 
    Chart.register(...registerables);
  }

  ngOnInit() {}
  
  ngAfterViewInit() {
    this.lineChartMethod(this.globalStatics[0].label, this.globalStatics[0].data);
  }

  generateCharts(chart) {
    this.lineChartMethod(this.globalStatics[chart].label, this.globalStatics[chart].data);
  }

  lineChartMethod(label, data) {
    if (this.lineChart) {
      this.lineChart.data.datasets[0].label = label;
      this.lineChart.data.datasets[0].data = data;
      this.lineChart.update();
    } else {

      this.lineChart = new Chart(this.lineCanvas.nativeElement, {
        type: 'line',
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            }
          },
          scales: {
            x: {
              display: true,
              grid: {
                drawOnChartArea: true
              }
            },
            y: {
              display: false,
              grid: {
                drawOnChartArea: false
              },
              beginAtZero: true,
              ticks: {
                padding: 10 // Ajusta el padding entre las etiquetas y el borde del canvas
              }
            }
          },
          layout: {
            padding: {
              top: 20
            }
          }
        },
        data: {
          labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          datasets: [
            {
              label: label,
              fill: true,
              backgroundColor: (context) => {
                const chart = context.chart;
                const { ctx, chartArea } = chart;
      
                if (!chartArea) {
                  // Este caso ocurre en el renderizado inicial del gráfico
                  return null;
                }
      
                const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
                gradient.addColorStop(0, '#007be7'); // El color principal
                gradient.addColorStop(1, 'rgba(75,192,192,0.0)'); // Transparente
      
                return gradient;
              },
              borderColor: '#007be7',
              borderWidth: 2,
              tension: 0.3,
              borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: '#007be7',
              pointBackgroundColor: '#fff',
              pointBorderWidth: 4,
              pointHoverRadius: 6,
              pointHoverBackgroundColor: '#007be7',
              pointHoverBorderColor: 'rgba(220,220,220,1)',
              pointHoverBorderWidth: 0,
              pointRadius: 0,
              pointHitRadius: 10,
              data: data,
              spanGaps: false,
            }
          ]
        }
      });

      // this.lineChart = new Chart(this.lineCanvas.nativeElement, {
      //   type: 'line',
      //   options : {
      //     plugins: {
      //       legend: {
      //         display: false
      //       }
      //     }
      //   },
      //   data: {
      //     labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      //     datasets: [
      //       {
      //         label: label,
      //         fill: true,
      //         // lineTension: 0.1,
      //         backgroundColor: '#007be7',
      //         borderColor: 'rgba(75,192,192,1)',
      //         borderCapStyle: 'butt',
      //         borderDash: [],
      //         borderDashOffset: 0.0,
      //         borderJoinStyle: 'miter',
      //         pointBorderColor: 'rgba(75,192,192,1)',
      //         pointBackgroundColor: '#fff',
      //         pointBorderWidth: 1,
      //         pointHoverRadius: 5,
      //         pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      //         pointHoverBorderColor: 'rgba(220,220,220,1)',
      //         pointHoverBorderWidth: 2,
      //         pointRadius: 4,
      //         pointHitRadius: 10,
      //         // data: [65, 59, 80, 81, 56, 55, 40, 10, 5, 50, 10, 15, 0],
      //         data: data,
      //         spanGaps: false,
      //       }
      //     ]
      //   }
      // });
    }
  }

  selectType(type) {
    const ids = ["it-g", "it-l"];
    const cls = "item md item-lines-none item-fill-none ion-activatable ion-focusable hydrated item-label";
    for (let i of ids) {
      let it: any = document.getElementById(i);
      if(type === i) {
        it.className = cls + " it-selected";
      } else {
        it.className = cls;
      }
    }
  }

  selectFilter(type) {
    const ids = ["it-fd", "it-fm", "it-fy"];
    const cls = "item md item-lines-none item-fill-none ion-activatable ion-focusable hydrated item-label";
    for (let i of ids) {
      let it: any = document.getElementById(i);
      if(type === i) {
        it.className = cls + " it-selected";
      } else {
        it.className = cls;
      }
    }
  }

  filterByTime(e) {
    console.log(e)
  }

  loadStatics() {
    this.globalItems = 0; 
    this.globalStock = 0; 
    this.globalCapital = 0;
    console.log("loading products");
    this.storage.get("token").then((tk) => {
      let postData: any = {
        token: tk,
      };

      const data = JSON.stringify(postData);
      
      this.httpParser.dcpayload(data, true)
        .then((postToSend: any) => {
          this.http.post(postToSend?.url + 'products/get_products.php',
          {
            data: postToSend?.value
          }, 
          {responseType: 'json'}
          ).pipe(finalize(() => {})).subscribe((data: any) => {
            if (data?.error) {
              if (data.error === '1-0') {
                this.presentToast('Error al guardar producto.');
              }
            } else if (data?.data) {
              this.httpParser.dcpayload(data.data, false).then((response: any) => {
                if(response.token) {
                  this.storage.set("token", response.token);

                  this.products = JSON.parse(response.data);
                  this.globalItems = this.products.length;
                  for(let i of this.products) {
                    i.prd_sale_prices = JSON.parse(i.prd_sale_prices);
                    i.ssp = 0; i.txp = 0;
                    i.prd_taxes = JSON.parse(i.prd_taxes);
                    i.prd_variants = JSON.parse(i.prd_variants);
                    i.prd_images = JSON.parse(i.prd_images);
                    i.stores = JSON.parse(i.stores);
                    i.visible = true;
                    i.prd_stock = Number(i.prd_stock);
                    i.prd_alert_stock = Number(i.prd_alert_stock);
              
                    let cont_taxes = [];
                    for(let h of i.prd_taxes) {
                      let ind = "$";
                      if (!h.int) {
                        ind = "%";
                      }

                      let name = h.name.split("(")[0];
                      if(h.enable) {
                        cont_taxes.push({name: name, id: ind, value: h.value, enable: h.enable});
                      }
                    }
                    i.prd_taxes = cont_taxes;

                    for(let h of i.stores) {
                      this.globalStock += h.stock;
                      this.globalCapital += Number(i.prd_cost) * h.stock;
                    }
                  }
                  this.globalStatics[0].data[2] = this.globalItems;
                  this.globalStatics[1].data[2] = this.globalStock;
                  this.globalStatics[2].data[2] = this.globalCapital;

                  this.lineChartMethod(this.globalStatics[0].label, this.globalStatics[0].data);
                  this.ch.detectChanges();
                } else {
                  this.presentToast('Error: El producto no ha sido guardado')
                }
              }).catch(err => console.log(JSON.stringify(err)));
            }
          }, err => {
            if (err.error === '1-0') {
              this.presentToast('No existe ninguna cuenta con esta dirección de Email');
            }
            console.log(JSON.stringify(err));
          });
        });
    });
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000
    });
    toast.present();
  }
}
