import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LenguagesService {

  public appPages = [
    { title: 'Inicio', url: '/login/users', icon: 'storefront', },
    { title: 'Ingresar', url: '/login/users', icon: 'person', },
    { title: 'Registrarme', url: '/login/user', icon: 'mail', },
    { title: 'Contáctanos', url: '/Store/Purchase-order', icon: 'call', function: true}
  ];
  
  public languages = {
    esp: {
      pricing: [
        {
          title: 'Demo',
          type: 'Un mes',
          price: 0,
          dcto: 0,
          toggle: true,
          checked: false,
          totalPrice: 0,
          platforms: [
            {
              name: 'Dominio web',
              icon: 'globe',
              iconColor: 'normal',
              detail: 'Pago anual',
              price: 120000
            },
            {
              name: 'App en Google Play Store',
              icon: 'logo-android',
              iconColor: 'normal',
              detail: 'Único pago',
              price: 150000
            },
            {
              name: 'App en App Store',
              icon: 'logo-apple',
              iconColor: 'normal',
              detail: 'Único pago',
              price: 200000
            }
          ],
          contains: [
            {
              icon: 'checkmark',
              color: 'success',
              text: 'Tienda web'
            },
            {
              icon: 'checkmark',
              color: 'success',
              text: 'Pasarela de pagos'
            },
            {
              icon: 'checkmark',
              color: 'success',
              text: 'Editor web'
            },
            {
              icon: 'checkmark',
              color: 'success',
              text: 'Administrador de usuarios'
            },
            {
              icon: 'checkmark',
              color: 'success',
              text: 'Administrador de inventario'
            },
            {
              icon: 'checkmark',
              color: 'success',
              text: 'Administrador de ventas'
            }
          ]
        },
        {
          title: 'Basic',
          type: 'Mensual',
          price: 125000,
          dcto: 0,
          toggle: false,
          checked: true,
          totalPrice: 0,
          platforms: [
            {
              name: 'Dominio web',
              icon: 'globe',
              iconColor: 'success',
              detail: 'Pago anual',
              price: 48000
            },
            {
              name: 'App en Google Play Store',
              icon: 'logo-android',
              iconColor: 'success',
              detail: 'Único pago',
              price: 75000
            },
            {
              name: 'App en App Store',
              icon: 'logo-apple',
              iconColor: 'success',
              detail: 'Único pago',
              price: 100000
            }
          ],
          contains: [
            {
              icon: 'checkmark',
              color: 'success',
              text: 'Tienda web'
            },
            {
              icon: 'checkmark',
              color: 'success',
              text: 'Pasarela de pagos'
            },
            {
              icon: 'checkmark',
              color: 'success',
              text: 'Editor web'
            },
            {
              icon: 'checkmark',
              color: 'success',
              text: 'Administrador de usuarios'
            },
            {
              icon: 'checkmark',
              color: 'success',
              text: 'Administrador de inventario'
            },
            {
              icon: 'checkmark',
              color: 'success',
              text: 'Administrador de ventas'
            }
          ]
        },
        {
          title: 'Standart',
          type: 'Semestral',
          price: 750000,
          dcto: 10,
          toggle: false,
          checked: true,
          totalPrice: 0,
          platforms: [
            {
              name: 'Dominio web',
              icon: 'globe',
              iconColor: 'success',
              detail: 'Gratis',
              price: 0
            },
            {
              name: 'App en Google Play Store',
              icon: 'logo-android',
              iconColor: 'success',
              detail: 'Gratis',
              price: 0
            },
            {
              name: 'App en App Store',
              icon: 'logo-apple',
              iconColor: 'success',
              detail: 'Único pago',
              price: 100000
            }
          ],
          contains: [
            {
              icon: 'checkmark',
              color: 'success',
              text: 'Tienda web'
            },
            {
              icon: 'checkmark',
              color: 'success',
              text: 'Pasarela de pagos'
            },
            {
              icon: 'checkmark',
              color: 'success',
              text: 'Editor web'
            },
            {
              icon: 'checkmark',
              color: 'success',
              text: 'Administrador de usuarios'
            },
            {
              icon: 'checkmark',
              color: 'success',
              text: 'Administrador de inventario'
            },
            {
              icon: 'checkmark',
              color: 'success',
              text: 'Administrador de ventas'
            }
          ]
        },
        {
          title: 'Professional',
          type: 'Anual',
          price: 1500000,
          dcto: 20,
          toggle: false,
          checked: true,
          totalPrice: 0,
          platforms: [
            {
              name: 'Dominio web',
              icon: 'globe',
              iconColor: 'success',
              detail: 'Gratis',
              price: 0
            },
            {
              name: 'App en Google Play Store',
              icon: 'logo-android',
              iconColor: 'success',
              detail: 'Gratis',
              price: 0
            },
            {
              name: 'App en App Store',
              icon: 'logo-apple',
              iconColor: 'success',
              detail: 'Gratis',
              price: 0
            }
          ],
          contains: [
            {
              icon: 'checkmark',
              color: 'success',
              text: 'Tienda web'
            },
            {
              icon: 'checkmark',
              color: 'success',
              text: 'Pasarela de pagos'
            },
            {
              icon: 'checkmark',
              color: 'success',
              text: 'Editor web'
            },
            {
              icon: 'checkmark',
              color: 'success',
              text: 'Administrador de usuarios'
            },
            {
              icon: 'checkmark',
              color: 'success',
              text: 'Administrador de inventario'
            },
            {
              icon: 'checkmark',
              color: 'success',
              text: 'Administrador de ventas'
            }
          ]
        }
      ]
    }
  }
  /*------------APP COMPONENT--------------*/
  // public appPages = [
  //   { title: 'Inicio', url: '/Store/Home', icon: 'storefront', },
  //   { title: 'Mi cuenta', url: '/Store/Account', icon: 'person', },
  //   { title: 'Compras', url: '/Store/Shopping', icon: 'bag-handle', },
  //   { title: 'Favoritos', url: '/Store/Favorites', icon: 'heart', },
  //   { title: 'Historial', url: '/Store/Historial', icon: 'time', },
  //   { title: 'Ofertas', url: '/Store/Offers', icon: 'pricetags', },
  // ];

  public filter =  { title: 'Filtrar', icon: 'search', type: 'button' };
  public tag_filters =  [
    { title: 'Todos', icon: 'bookmark', id: 'all'},
    { title: 'Menor precio', icon: 'bookmark', id: 'menor'},
    { title: 'Mayor precio', icon: 'bookmark', id: 'mayor'}
  ];

  public categories = [
    { title: 'Carros', url: '/Store/Carros', icon: 'home' },
    { title: 'Motos', url: '/Store/Motos', icon: 'paper-plane' },
    { title: 'Varcos', url: '/Store/Varcos', icon: 'heart' },
    { title: 'Aviones', url: '/Store/Aviones', icon: 'archive' },
    { title: 'Ciclas', url: '/Store/Ciclas', icon: 'trash' },
    { title: 'Camiones', url: '/Store/Camiones', icon: 'warning' },
  ];

  public st_menu_categories = 'Categorías';
  /*------------FOLDER--------------*/
  public st_searchBar = 'Buscar';
  constructor() { }

  public setCategories(data) {
    this.categories = [];
    for(let i of data.categories) {
      this.categories.push({ title: i.name, url: '/Store/' + i.name, icon: i.icon });
    }
  }

  public setLenguageAtmosphere(){
    return this.categories;
  }
}
