import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ToastController, IonDatetime } from '@ionic/angular';
import { HttpParserService } from 'src/app/services/http-parser/http-parser.service';
import { HttpClient } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})

export class UsersComponent implements OnInit {
  @ViewChild('fileInput') fileInput: any;
  imagenSeleccionada: any = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";

  @ViewChild('myDatetime') myDatetime: IonDatetime;

  localAccounts: any = [];
  posAccounts: any = [];

  addingAccount = false;

  siginForm: any = [
    {
      id: 'name',
      name: 'Nombre*',
      type: 'text',
      value: '',
      require: true
    },
    {
      id: 'email',
      name: 'Email*',
      type: 'email',
      value: '',
      require: true
    },
    {
      id: 'tel',
      name: 'Número telefónico',
      type: 'tel',
      value: '',
      require: false
    },
    {
      id: 'pass',
      name: 'Contraseña*',
      type: 'password',
      value: '',
      require: true
    }
  ];

  rollType = 0;
  rollTypes = [{name: "Administrador", icon: "business-outline", value: "Admin"}, {name: "Empleado", icon: "person-outline", value: "Employee"}, {name: "Editor", icon: "color-palette-outline", value: "Editor"}];
  
  rollCategories = [{name: "Todo", icon: "business-outline", value: "all"}, {name: "Sistema Administrativo", icon: "briefcase-outline", value: "panel"}, {name: "Sistema POS", icon: "receipt-outline", value: "pos"}];
  rollCategory = 0;

  stores: any = [];
  storesToAdd: any = [];
  birth_date = "";
  b_d = "";
  birth_date_show = false;

  constructor(
    private toastController: ToastController,
    private httpParser: HttpParserService,
    private http: HttpClient,
    private storage: Storage,
    private cdr: ChangeDetectorRef
    ) {}

  ngOnInit() {
    this.storage.get("stores").then((result) => {
      this.stores = JSON.parse(result);
      for(let i of this.stores) {
        i.add = false;
      }
      // console.log(this.stores);
    });
  }

  loadAccounts() {
    console.log("loading accounts");
    this.storage.get("token").then((tk) => {
      let postData: any = {
        token: tk,
      };

      const data = JSON.stringify(postData);
      
      this.httpParser.dcpayload(data, true)
        .then((postToSend: any) => {
          this.http.post(postToSend?.url + 'accounts/get_accounts.php',
          {
            data: postToSend?.value
          }, 
          {responseType: 'json'}
          ).pipe(finalize(() => {})).subscribe((data: any) => {
            if (data?.error) {
              if (data.error === '1-0') {
                this.presentToast('Error al guardar producto.');
              }
            } else if (data?.data) {
              this.httpParser.dcpayload(data.data, false).then((response: any) => {
                if(response.token) {
                  this.storage.set("token", response.token);
                  // susesfully
                  this.localAccounts = JSON.parse(response.data);
                  this.cdr.detectChanges();
                  console.log(this.localAccounts);
                } else if(response.error) {
                  this.presentToast('No tienes permisos para ver esta información');
                }
              }).catch(err => this.presentToast('Error: No se ha podido cargar la información'));
            }
          }, err => {
            if (err.error === '1-0') {
              this.presentToast('No tienes permisos para ver esta información');
            }
            console.log(JSON.stringify(err));
          });
        });
    });
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000
    });
    toast.present();
  }

  detectChanges() {
    this.cdr.detectChanges();
  }

  createUser() {
    if(this.storesToAdd.length === 0) {
      this.presentToast("Debe asignar almenos una tienda antes de guardar.")
    } else if(this.birth_date === "") {
      this.presentToast("Seleccione una fecha de nacimiento");
    } else {
      let ct = true;
      for (let i of this.siginForm) {
        if (i.value === "") {
          ct = false;
        }
      }

      if (ct) {
        this.presentToast("Guardando...");
        this.storage.get("token").then((tk) => {
          let postData: any = {
            adding: true,
            name: this.siginForm[0].value,
            email: this.siginForm[1].value,
            pass: this.siginForm[3].value,
            birthdate: this.b_d,
            phone_number: this.siginForm[2].value,
            location: "none",
            type: "user",
            token: tk,
            avatar_url: this.imagenSeleccionada,
            overload: {auth: this.storesToAdd}
          };

          const data = JSON.stringify(postData);
          this.imagenSeleccionada = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";

          this.httpParser.dcpayload(data, true)
          .then((postToSend: any) => {
            this.http.post(postToSend?.url + 'accounts/sigin_account.php',
            {
              data: postToSend?.value
            }, 
            {responseType: 'json'}
            ).pipe(finalize(() => {})).subscribe((data: any) => {
              if (data?.error) {
                this.presentToast(data?.error);
              } else if (data?.data) {
                this.httpParser.dcpayload(data.data, false).then((response: any) => {
                  if (response.data) {
                    this.addingAccount = false;
                    this.loadAccounts();
                    this.presentToast('Usuario creado con éxito');
                  } else {
                    this.presentToast('Error: El usuario no ha sido guardado');
                  }
                }).catch(err => this.presentToast('Error: No se ha podido registrar'));
              }
            }, err => {
              if (err.error === '1-0') {
                this.presentToast('Esta dirección de Email ya está en uso');
              }
            });
          });
        });

        console.log(this.siginForm);
      } else {
        this.presentToast("Debe llenar todo el formulario antes de guardar");
      }
    }
  }

  updateForm(index: any, event: any) {
    this.siginForm[index] = Object.assign(this.siginForm[index], {value: event.detail.value});
  }

  setStores(ev, index) {
    this.stores[index].add = ev.detail.checked;

    if (this.rollType === 2) {
      this.rollCategory = 1;
    } else if (this.rollType === 0) {
      this.rollCategory = 0;
    }

    let container = [];
    for(let i of this.stores) {
      if(i.add) {
        container.push(
          {
            store_id: i.store_id, 
            store_name: i.store_name, 
            roll: this.rollTypes[this.rollType].value,
            access: this.rollCategories[this.rollCategory].value
          });
      }
    }

    this.storesToAdd = container;
  }

  setDateTime() {
    this.myDatetime.confirm().then(() => {
      this.birth_date = this.myDatetime.value.split("T")[0];
      this.b_d = this.myDatetime.value;
      this.birth_date_show = false;
    });
  }

  seleccionarImagen() {
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event) {
    // Cuando el usuario selecciona una imagen, se maneja aquí
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imagenSeleccionada = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }
}
